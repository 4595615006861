import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../App.js";
import { Container, Row, Col, Spinner, Form, Button } from 'react-bootstrap';
import { useNavigate, Link } from "react-router-dom";
import { IconFind, IconAdd, IconStop, IconWallet, IconHand } from "../../components/Icons.js";
import AdminPanel from "../../components/AdminPanel/AdminPanel.js";
import AdminMenu from "../../components/AdminMenu/AdminMenu.js";
import { GetTemplate } from "../../components/DialogTemplates/GetTemplate.js";



import "./Style.css";

const ClientClients = (props) => {


    const [appData, setAppContext] = useContext(AppContext);


    const [findValue, setFindValue] = useState(JSON.parse(sessionStorage.getItem(appData.guid + "_clients_list_find")));
    const [clientsList, setClientsList] = useState(JSON.parse(sessionStorage.getItem(appData.guid + "_clients_list_cash")));



    const navigate = useNavigate();

    useEffect(() => {
        const handleBackButton = () => {
            navigate(-1);
        }

        window?.Telegram.WebApp.BackButton.show();
        window?.Telegram.WebApp.BackButton.onClick(handleBackButton);

        return () => {
            window?.Telegram.WebApp.BackButton.hide();
            window?.Telegram.WebApp.BackButton.offClick(handleBackButton);
        };

    }, []);


    useEffect(() => {

        let find = findValue ? findValue : "";

        const controller = new AbortController();

        //setClientsList();
        axios.get("/api/tenant_v1/clients/?find=" + find, {
            signal: controller.signal
        }).then((result) => {
            setClientsList(result.data);
            sessionStorage.setItem(appData.guid + "_clients_list_find", JSON.stringify(find));
            sessionStorage.setItem(appData.guid + "_clients_list_cash", JSON.stringify(result.data));
        });

        return () => {
            controller.abort();
        };

    }, [findValue]);

    const handleAddClient = () => {
        GetTemplate(
            (template) => {
                window.Telegram.WebApp.showConfirm(template, (result) => {
                    if (result) {
                        navigate("/app/master/tenant/clients/item/0/")
                    }
                });
            }, "DialogNewClientManually");
    }

    return (
        <>

            <AdminPanel />
            <AdminMenu title="Мои клиенты" />

            <Container className="admin admin-clients">

                <Button className="w-100 mb-2" variant="base" onClick={handleAddClient}>
                    Новый клиент
                    <IconAdd className="float-end me-1" />
                </Button>

                <div className="find mb-2">
                    <Form.Label htmlFor="basic-url">Поиск клиента</Form.Label>
                    <Form.Control type="text" placeholder="Введите ФИО или номер телефона"
                        defaultValue={findValue} onChange={(el) => setFindValue(el.target.value)}
                    />
                    <IconFind className="icon" />
                </div>

                {clientsList ? (
                    <>
                        {clientsList.clients.length === 0 && (
                            <h1>Клиентов нет</h1>
                        )}

                        {clientsList.clients?.map((item, index) => (

                            // <Link to={"/app/master/tenant/clients/item/" + item.guid + "/"}>
                            <div key={index} onClick={() => navigate("/app/master/tenant/clients/item/" + item.guid + "/")} className={"item " + item.variant}>
                                <Row className={"p-2"}>
                                    <Col className="col-11">
                                        <div className='client-info'>
                                            {item.name}{item.telegram_phone ? ", " + item.telegram_phone : ""}
                                        </div>

                                        {item.client_balance && (
                                            <div className='client-balance'>
                                                <IconWallet /> {item.client_balance} ₽
                                            </div>
                                        )}
                                    </Col >
                                    <Col className="col-1">


                                        {!Boolean(item.telegram_id) && (
                                            <IconHand className="float-end" />
                                        )}

                                        {item.black_list && (
                                            <IconStop className="float-end" />
                                        )}
                                    </Col>
                                </Row>
                            </div>

                            // </Link> 
                        ))}
                    </>

                ) : (
                    <Row className="vh-100 text-center">
                        <Col className="mt-5">
                            <Spinner animation="border" variant="secondary" />
                        </Col>
                    </Row >
                )}
            </Container >
        </>
    );
};

export default ClientClients;


