import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Spinner } from 'react-bootstrap';
import { GetTemplate } from "../../components/DialogTemplates/GetTemplate.js";
import AdminPanel from "../../components/AdminPanel/AdminPanel";
import AdminMenu from "../../components/AdminMenu/AdminMenu";
import DialigConfirm from "../../components/DialigConfirm/DialigConfirm";

const AdminSettings = (props) => {

  //const Permissions = useContext(AppContext);

  const [settings, setSettings] = useState();
  const [formValue, setformValue] = useState({});
  //  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleBackButton = () => {
      navigate(-1)
    }

    window?.Telegram.WebApp.BackButton.show();
    window?.Telegram.WebApp.BackButton.onClick(handleBackButton);

    return () => {
      window?.Telegram.WebApp.BackButton.hide();
      window?.Telegram.WebApp.BackButton.offClick(handleBackButton);
    };

  }, []);

  useEffect(() => {
    axios.get("/api/tenant_v1/settings/").then((result) => {
      setSettings(result.data);
    });
  }, []);

  const handleChange = (event) => {
    setSubmitVisible(true);
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value
    });
  }

  const handleSubmit = () => {
    // e.preventDefault();
    setSubmitVisible(false);
    axios.patch("/api/tenant_v1/settings/", formValue).then((result) => {
      window.scrollTo(0, 0);
      setSettings(result.data);
      // GetTemplate((template) =>
      //   window?.Telegram?.WebApp.showAlert(template),
      //   "DialogSaveBotSettings"
      // );
    });
  }

  return (
    <>

      <AdminPanel />

      <AdminMenu title="Страница настроек" />

      <Container className="admin">

        {settings ?
          (
            <Form >

              <Form.Group className="mb-3" controlId="settings.time_zone">
                <Form.Label>Ваш часовой пояс</Form.Label>
                <Form.Select defaultValue={settings?.time_zone_guid} name="time_zone" onChange={handleChange}>
                  {settings?.time_zone_list?.map((item) => (
                    <option key={item.guid} value={item.guid}>{item.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>


              <Form.Group className="mb-3" controlId="settings.period_schedule">
                <Form.Label>Шаг (интервал) записи</Form.Label>
                <Form.Select defaultValue={settings?.period_schedule} name="period_schedule" onChange={handleChange}>
                  {settings?.period_schedule_list?.map((item) => (
                    <option key={item.value} value={item.value}>{item.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>


              <Form.Group className="mb-3">
                <Form.Label>Адрес мастера</Form.Label>
                <Form.Control as="textarea" rows={2} defaultValue={settings?.service_address} name="service_address" onChange={handleChange} />
              </Form.Group>

              {/*
              <Form.Group className="mb-3">
                <Form.Label>Название бота</Form.Label>
                <Form.Control type="text" placeholder="Введите название бота" defaultValue={settings?.name} name="name" onChange={handleChange} />
              </Form.Group>

              
              <Form.Group className="mb-3">
                <Form.Label>Краткое описание бота</Form.Label>
                <Form.Control as="textarea" rows={2} defaultValue={settings?.short_description} name="short_description" onChange={handleChange} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Полное описание бота</Form.Label>
                <Form.Control as="textarea" rows={4} defaultValue={settings?.description} name="description" onChange={handleChange} />
              </Form.Group> */}

              <DialigConfirm
                show={submitVisible}
                handleYes={handleSubmit}
                handleCancel={() => navigate(-1)}
              />

            </Form>
          )
          :
          (
            <Row className="vh-100 text-center">
              <Col className="mt-5">
                <Spinner animation="border" variant="secondary" />
              </Col>
            </Row>
          )
        }

      </Container>
    </>
  );
};

export default AdminSettings;
