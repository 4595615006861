import { useEffect } from "react";
import { Container } from 'react-bootstrap';
import AdminPanel from "../../components/AdminPanel/AdminPanel.js";
import ClientMenu from "../../components/ClientMenu/ClientMenu.js";
import { useNavigate } from "react-router-dom";
import ClientRecordsAccordion from "./ClientRecordsAccordion.js";

const ClientRecords = () => {

    const navigate = useNavigate();
    //   const [appData, setAppContext] = useContext(AppContext);

    useEffect(() => {

        const handleBackButton = () => {
            //            navigate(-1);
            navigate("/app/master/client/");
        }

        window?.Telegram.WebApp.BackButton.show();
        window?.Telegram.WebApp.BackButton.onClick(handleBackButton);

        return () => {
            window?.Telegram.WebApp.BackButton.hide();
            window?.Telegram.WebApp.BackButton.offClick(handleBackButton);
        };

    }, []);


    return (
        <>
            <AdminPanel />
            <Container className="mt-3 client" style={{ marginBottom: "110px" }}>
                <ClientMenu />
                <h1 className="mt-4 mb-4">Мои записи</h1>
                <ClientRecordsAccordion openAccordion={"actived"} restoreRecord={false} />
            </Container >
        </>
    );
};

export default ClientRecords;
