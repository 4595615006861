import { IconHelp } from "../Icons.js";
import { GetTemplate } from "./GetTemplate.js";

const IconPopUpHelp = (props) => {

    let { code } = props;

    const handleHelpCode = (code) => {
        GetTemplate((template) => window?.Telegram?.WebApp.showAlert(template), code)
    }

    return (
        <span onClick={() => handleHelpCode(code)}>
            <IconHelp className="ps-2 pe-2" />
        </span >
    );

};

export default IconPopUpHelp;