// import axios from "axios";
// import { useEffect, useState } from "react";
// import Footer from "../components/footer";
// import Header from "../components/header";
// import StoreSelect from "../components/store/store";
// import { apiUrl } from "../../index";
// // import ListProducts from "./ListProducts";
// import "./catalog.css";
// import { addBackLevel } from "../index";

// import Button from "react-bootstrap/Button";
// import Carousel from "react-bootstrap/Carousel";
// import Image from "react-bootstrap/Image";
// import Modal from "react-bootstrap/Modal";
// import Placeholder from "react-bootstrap/Placeholder";
// import ToggleButton from "react-bootstrap/ToggleButton";
// import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";

//axios.defaults.withCredentials = true;
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { NavLink } from "react-router-dom";

// import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import ToggleButton from 'react-bootstrap/ToggleButton';
// import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { AppContext } from "../../App.js";


// import { v4 as uuidv4 } from "uuid";

const AdminPanel = () => {


    let location = useLocation();
    const navigate = useNavigate();
    const [appData, setAppContext] = useContext(AppContext);
    const [tab, setTab] = useState(window.location.href.indexOf("/tenant/") !== -1 ? "tenant" : "client");

    useEffect(() => {
        setTab(location.pathname.indexOf("/tenant/") !== -1 ? "tenant" : "client");



        // window.ym(98057496, 'hit');
        // console.log("location", location);




    }, [location]);

    const tabSelect = (i) => {
        setAppContext({ ...appData, "basket": {} }); // очищаем корзину
        navigate('/app/master/' + i + "/");
    }

    return (
        appData.permissions.includes("tenant") && (

            <Tabs
                activeKey={tab}
                className='pt-2 ps-2 pe-2'
                onSelect={tabSelect}

            >
                <Tab eventKey="client" title="Страница клиента" ></Tab>
                <Tab eventKey="tenant" title="Кабинет мастера" ></Tab>
            </Tabs>


            // <NavLink
            //     to={"/app/master/public/"}
            //     className={"btn btn-light"}
            //     disabled={({ isActive }) => isActive ? true : false}
            // >Страница клиента</NavLink >

        )

    );
};

export default AdminPanel;
