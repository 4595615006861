import { useContext } from "react";
import { Button } from 'react-bootstrap';
import { timeFormat } from "../../components/tools.js";
import { IconHourglass, IconMoney, IconNext } from "../../components/Icons.js";
import './Style.css';
import { AppContext } from "../../App.js";

const ClientMainButton = (props) => {

    const [appData] = useContext(AppContext);
    //console.log("appData?.basket", appData?.basket);

    return (
        <footer className={"fixed-bottom text-center main-button" + (props.disabled ? " disabled" : "")} style={{ "transform": appData?.basket?.count ? "scaleY(1)" : "scaleY(0)" }} >
            <div className="header">
                {appData?.basket?.selected_client && (
                    <div>
                        {"Выбран клиент: "}
                        {appData?.basket?.selected_client.name}
                        {appData?.basket?.selected_client.phone ? ", " + appData?.basket?.selected_client.phone : ""}
                    </div>
                )}
                Выбрано услуг: <span className="perimeter">{appData?.basket?.count}</span>
                <IconHourglass /> {timeFormat(appData?.basket?.duration)}
                <IconMoney /> {Number(appData?.basket?.price).toLocaleString('ru-RU')} ₽
            </div>
            <Button variant={"main"} disabled={props.disabled} onClick={props.handleMainButtonClick}>
                {props.mainButtonText} <IconNext />
            </Button>
        </footer >
    );
};

export default ClientMainButton;
