// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.records .item {
  border: solid 1px var(--theme-icon-color);
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}
/* 
.records .item .total {
  font-size: 0.75rem;
}

.records .item .description {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  white-space: pre-wrap;
} */

.records .archived .item {
  filter: grayscale(75%);
  opacity: 0.85;
}
`, "",{"version":3,"sources":["webpack://./src/appMaster/ClientRecords/Style.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,qBAAqB;EACrB,qBAAqB;EACrB,mBAAmB;AACrB;AACA;;;;;;;;;GASG;;AAEH;EACE,sBAAsB;EACtB,aAAa;AACf","sourcesContent":[".records .item {\n  border: solid 1px var(--theme-icon-color);\n  border-radius: 0.5rem;\n  margin-bottom: 0.5rem;\n  font-size: 0.875rem;\n}\n/* \n.records .item .total {\n  font-size: 0.75rem;\n}\n\n.records .item .description {\n  margin-top: 0.5rem;\n  margin-bottom: 0.5rem;\n  white-space: pre-wrap;\n} */\n\n.records .archived .item {\n  filter: grayscale(75%);\n  opacity: 0.85;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
