import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import AdminPanel from "../../components/AdminPanel/AdminPanel.js";
import AdminMenu from "../../components/AdminMenu/AdminMenu.js";

import { GetTemplate } from "../../components/DialogTemplates/GetTemplate.js";

const AdminManualMailings = (props) => {

  //const Permissions = useContext(AppContext);

  const [formValue, setformValue] = useState({ "filter": "all" });
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    const handleBackButton = () => {
      navigate(-1);
    }

    window?.Telegram.WebApp.BackButton.show();
    window?.Telegram.WebApp.BackButton.onClick(handleBackButton);

    return () => {
      window?.Telegram.WebApp.BackButton.hide();
      window?.Telegram.WebApp.BackButton.offClick(handleBackButton);
    };

  }, []);

  const handleChange = (event) => {
    setSubmitEnabled(true);
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitEnabled(false);
    axios.post("/api/tenant_v1/manual_mailings", formValue).then((result) => {
      setformValue({ ...formValue, message: "" });
      //window?.Telegram?.WebApp.showAlert("Сообщение отправляется");

      GetTemplate((template) =>
        window?.Telegram?.WebApp.showAlert(template),
        "DialogSendingMessagesToClients"
      );

    });
  }

  return (
    <>

      <AdminPanel />

      <AdminMenu title="Отправка сообщений" />

      <Container>
        <Form onSubmit={handleSubmit} >

          <Form.Group className="mb-3" controlId="settings.time_zone">
            <Form.Label>Кому отправляем</Form.Label>
            <Form.Select defaultValue={formValue.filter} name="filter" onChange={handleChange}>

              <option value="all">Всем</option>
              <option value="all" disabled>Тем, кто ни разу не записывался</option>
              <option value="all" disabled>Тем, у кого есть задолженность</option>
              <option value="all" disabled>Последний раз записывался х дней назад</option>

            </Form.Select>
          </Form.Group>


          <Form.Group className="mb-3">
            <Form.Label>Текст сообщения</Form.Label>
            <Form.Control as="textarea" rows={4} name="message" value={formValue.message} onChange={handleChange} />
          </Form.Group>

          <div className="text-center mb-5">
            <Button type="submit" variant="base" disabled={!submitEnabled} className="ps-4 pe-4">Отправить</Button>
          </div>

        </Form>

      </Container>
    </>
  );
};

export default AdminManualMailings;
