import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { timeFormat } from "../../components/tools.js";
import CalendarHeader from "../../components/CalendarHeader/CalendarHeader.js";
import { useNavigate } from "react-router-dom";
import { DateTime } from 'luxon';
import AdminPanel from "../../components/AdminPanel/AdminPanel";
import ClientMenu from "../../components/ClientMenu/ClientMenu";
import ClientMainButton from "../../components/ClientMainButton/ClientMainButton.js";
import { GetTemplate } from "../../components/DialogTemplates/GetTemplate.js";
import { PopUpAlert } from "../../components/DialogTemplates/PopUpAlert.js";



import { AppContext } from "../../App.js";

const ClientCalendar = (props) => {

    const [appData, setAppContext] = useContext(AppContext);

    const navigate = useNavigate();
    const [records, setRecords] = useState();
    const [calendar, setCalendar] = useState();
    const [recordDate, setRecordDate] = useState(false);

    const DateTimeServerNow = () => {
        return DateTime.now().plus({ milliseconds: appData.time_zone_delta });
    }

    const [calendarDate, setCalendarDate] = useState(DateTimeServerNow());

    useEffect(() => {
        console.log("useEffect handleBackButton");

        const handleBackButton = () => {
            navigate(-1);
        }

        window?.Telegram.WebApp.BackButton.show();
        window?.Telegram.WebApp.BackButton.onClick(handleBackButton);

        return () => {
            window?.Telegram.WebApp.BackButton.hide();
            window?.Telegram.WebApp.BackButton.offClick(handleBackButton);
        };

    }, []);

    const handleCalendar = (date) => {
        setRecordDate(false);
        setCalendarDate(date);
    };

    const handleSaveRecord = (item) => {

        axios.post("/api/client_v1/records/", {
            data: { record: { ...appData.basket, start: recordDate }, client_guid: appData?.basket?.selected_client?.guid }
        }).then((result) => {
            if (result?.data?.error) {
                window.Telegram.WebApp.showAlert(result?.data?.error);
                setCalendar(result.data);
            } else {
                //Запись прошла успешно
                let link;
                let timeText = recordDate.setLocale("ru").toFormat("d MMMM на H:mm");

                if (appData?.basket?.selected_client?.guid) {
                    //msgText = "Клиент: " + appData.basket?.selected_client.name + " записан " + timeText;
                    PopUpAlert("PopUpCalendarStatus-recorded-by-master", timeText, appData.basket?.selected_client.name);

                    link = "/app/master/tenant/calendar/" + recordDate.toISODate() + "/";
                } else {

                    PopUpAlert("PopUpCalendarStatus-recorded", timeText);
                    link = "/app/master/client/records/";
                }

                setAppContext({ ...appData, "basket": {} });
                navigate(link);

            }
        });
    };

    useEffect(() => {
        const controller = new AbortController();

        if (calendarDate) {
            setCalendar(false);
            axios.get("/api/client_v1/calendar/" + calendarDate.toFormat("yyyy-MM-dd") + "/", {
                signal: controller.signal
            }).then((result) => {
                setCalendar(result.data);
            });
        }

        return () => {
            controller.abort();
        };
    }, [calendarDate]);



    useEffect(() => {

        function plus_period(value, period, max_value) {
            let endPeriod = value.plus({ minutes: period });
            return endPeriod > max_value ? max_value : endPeriod;
        }

        if (calendar?.client_records && calendar?.work_schedule.length > 0) {

            //let key = 0;
            let result = [];

            //Период от начала до окончания заполняем как Перерыв у мастера
            // let startTime = DateTime.fromISO(calendar.work_schedule[0].start);
            // let endTime = DateTime.fromISO(calendar.work_schedule.at(-1).end);

            // for (let time = startTime; time < endTime; time = time.plus({ minutes: calendar?.period })) {
            //     result[time.toUnixInteger()] = {
            //         index: time.toUnixInteger(),
            //         start: time,
            //         end: plus_period(time, calendar?.period, endTime),
            //         type: "break",
            //         key: key,
            //         text: "Перерыв у мастера"
            //     };
            // }

            calendar?.work_schedule.forEach((period, index) => {

                //Заполняем перерывы

                console.log("calendar?.work_schedule.indexOf(index + 1)", calendar?.work_schedule.indexOf(index + 1));

                if (calendar?.work_schedule[index + 1]?.start) {

                    let startTime = DateTime.fromISO(period.end);
                    let endTime = DateTime.fromISO(calendar.work_schedule[index + 1].start);

                    result.push({
                        //index: startTime.toUnixInteger(),
                        start: startTime,
                        end: endTime,
                        type: "break",
                        // key: key,
                        text: "Перерыв у мастера"
                    });

                }
            });

            console.log("result1", result);
            console.log("result.length1", result.length);

            //Сверху накладываем расписание мастера
            calendar?.work_schedule.forEach((period, index) => {
                let startTime = DateTime.fromISO(period.start);
                let endTime = DateTime.fromISO(period.end);
                //key++;
                for (let time = startTime; time < endTime; time = time.plus({ minutes: calendar?.period })) {
                    result.push({
                        //index: time.toUnixInteger(),
                        start: time,
                        end: plus_period(time, calendar?.period, endTime),
                        type: "plan",
                        // key: key,
                        text: "Свободно",
                        short: endTime < time
                    });
                }
            });

            console.log("result2", result);
            console.log("result.length2", result.length);


            //Сверху накладываем записи клиентов
            calendar?.client_records.forEach((period, index) => {


                console.log("records_period", period);
                console.log("records_index", index);

                let startTime = DateTime.fromISO(period.start);
                let endTime = DateTime.fromISO(period.end);
                let endPeriodTime = DateTime.fromISO(period.end);


                // ищем окончание занятого периода
                result.forEach((work_period, work_index) => {
                    if ((startTime <= work_period.start && work_period.start <= endTime)) {
                        endPeriodTime = work_period.end;
                        //result[work_index].key = key;
                        // result[work_index].type = "used";
                        // result[work_index].text = "Занято";
                    }
                });


                //Удаляем свободные интервалы 
                result = result.filter((check_period) =>
                    !(startTime <= check_period.start && endTime >= check_period.start)
                );

                result.push({
                    //client_record: period,
                    //index: time.valueOf(),
                    start: startTime,
                    end: endPeriodTime, // endTime, 
                    type: "used",
                    text: "Занято"
                    //key: key
                });



                // for (let time = startTime; time < endTime; time = time.plus({ minutes: calendar?.period })) {
                //     result[startTime.toUnixInteger()] = {
                //         //index: time.toUnixInteger(),
                //         start: startTime,
                //         end: endTime,
                //         type: "used",
                //         key: key,
                //         guid: period.guid,
                //         text: "Занято"
                //     };
                // }

            });

            console.log("result3", result);
            console.log("result.length3", result.length);

            // console.log("rhlkfjelrkjflekrnjflwkejrnflwkejr");
            // //поиск подходящих периодов
            // calendar?.work_schedule.forEach((period, index) => {
            //     let startTime = DateTime.fromISO(period.start);
            //     let endTime = DateTime.fromISO(period.end);

            //     //Ищем периоды подходящей длительности
            //     //let masterTimeNow = DateTimeServerNow();
            //     //startTime = DateTime.fromISO(calendar.work_schedule[0].start);
            //     //endTime = DateTime.fromISO(calendar.work_schedule.at(-1).end);

            //     for (let time = startTime; time < endTime; time = time.plus({ minutes: calendar?.period })) {

            //         //удаляем уже прошедшее время
            //         //if (time < masterTimeNow) {
            //         //    delete result[time.toUnixInteger()];
            //         //    continue;
            //         //};

            //         //период свободный
            //         if (result[time.toUnixInteger()].type === "plan") {
            //             let startCheckTime = time;
            //             let endCheckTime = time.plus({ minutes: appData.basket.duration });
            //             let check = true;
            //             let period_duration = 0;

            //             //for (let checkTime = startCheckTime; checkTime < endCheckTime; checkTime = checkTime.plus({ minutes: calendar?.period })) {

            //             for (let checkTime = startCheckTime; checkTime < endCheckTime; checkTime = plus_period(checkTime, calendar?.period, endTime)) {

            //                 if (result[checkTime.toUnixInteger()]?.type !== "plan") {
            //                     check = false;
            //                     break;
            //                 }
            //                 period_duration += calendar?.period;
            //             }

            //             if (check) {
            //                 result[time.toUnixInteger()].key = ++key;
            //                 result[time.toUnixInteger()].type = "free";
            //                 result[time.toUnixInteger()].text = "Cвободно";
            //             } else {
            //                 //result[time.toUnixInteger()].key = ++key;
            //                 result[time.toUnixInteger()].period_duration = period_duration;
            //                 result[time.toUnixInteger()].type = "short";
            //                 result[time.toUnixInteger()].text = "Частично свободно";
            //             }
            //         }
            //     }
            // });



            // сортируем и реиндексируем
            let result_sort = result.sort((a, b) => a.start - b.start);


            //переиндексируем
            // let result_sort = [];
            // for (let index in result) {
            //     result_sort.push(result[index]);
            // }

            console.log("rhlkfjelrkjflekrnjflwkejrnflwkejr");
            console.log("result_sort", result_sort);
            //поиск подходящих периодов
            result_sort.forEach((period, index) => {

                //let startTime = DateTime.fromISO(period.start);
                //let endTime = DateTime.fromISO(period.end);

                //Ищем периоды подходящей длительности
                //let masterTimeNow = DateTimeServerNow();
                //startTime = DateTime.fromISO(calendar.work_schedule[0].start);
                //endTime = DateTime.fromISO(calendar.work_schedule.at(-1).end);

                //for (let time = startTime; time < endTime; time = time.plus({ minutes: calendar?.period })) {

                //удаляем уже прошедшее время
                //if (time < masterTimeNow) {
                //    delete result[time.toUnixInteger()];
                //    continue;
                //};

                //ищем подходящий период
                if (period.type === "plan") {
                    //let startCheckTime = period.start;
                    let endCheckTime = period.start.plus({ minutes: appData.basket.duration });
                    let check = false;
                    //let period_duration = 0;

                    //for (let checkTime = startCheckTime; checkTime < endCheckTime; checkTime = checkTime.plus({ minutes: calendar?.period })) {

                    //for (let checkTime = startCheckTime; checkTime < endCheckTime; checkTime = plus_period(checkTime, calendar?.period, endTime)) {
                    for (let index_next = index; index_next < result_sort.length; index_next++) {

                        if (result_sort[index_next].type !== "plan") {
                            check = false;
                            break;
                        }
                        if (result_sort[index_next].end >= endCheckTime) {
                            check = true;
                            break;
                        }

                    }

                    if (check) {
                        //  result_sort[index].key = ++key;
                        result_sort[index].type = "free";
                        result_sort[index].text = "Cвободно";
                    } else {
                        //result[time.toUnixInteger()].key = ++key;
                        //result_sort[index].period_duration = period_duration;
                        result_sort[index].type = "short";
                        result_sort[index].text = "Частично свободно";
                    }
                }
                //}
            });

            let masterTimeNow = DateTimeServerNow();


            setRecords(result_sort.filter(val => (val.start > masterTimeNow)));

            //группируем однотипные периоды
            // //let group = { periods: [] };
            // let result_group = [];

            // // let masterTimeNow = DateTimeServerNow();

            // result_sort.forEach((item) => {
            //     if (item.start > masterTimeNow) {
            //         result_group.push({ ...item, periods: { "start": item.start, "end": item.end } });
            //     }
            // });

            // setRecords(result_group);
        } else {
            setRecords();
        }

    }, [calendar]);


    const handleCalendarClick = (record) => {

        console.log("record", record);

        let msgCode = record?.type;
        // let msgList = {
        //     short: "Свободно " + + " этого времени недостаточно для записи, уменьшите количество услуг!",
        //     record: "Это время уже занято, попробуйте поискать свободное!",
        //     break: "Это время уже занято, попробуйте поискать свободное!"
        // };

        // let WebApp = window.Telegram.WebApp;

        if (record.start < DateTimeServerNow()) {
            msgCode = "offside";
        }

        if (msgCode) {

            let duration = (record.end - record.start) / 60 / 1000;

            console.log("duration", duration);

            GetTemplate((template) =>
                window?.Telegram?.WebApp.showAlert(template),
                "PopUpCalendarStatus-" + msgCode,
                timeFormat(duration)
            );

        }

        if (record.type === "free") {
            setRecordDate(record.start);
        }

        if (record.type === "short") {
            setRecordDate();
        }

    }

    return (
        <>

            <AdminPanel />

            <Container className="mt-3 client" style={{ marginBottom: "110px" }}>
                <ClientMenu />

                <Row >
                    <Col className="text-center"><h1 className="mt-3 mb-3">Выберите дату и время</h1></Col>
                </Row>

                <CalendarHeader calendarMinDate={DateTimeServerNow()} calendarDate={calendarDate} handleUpdateCalendarDate={handleCalendar} />

                {calendar ? (
                    <>
                        {calendar?.work_schedule?.length === 0 && (
                            <h1 className="mt-4 mb-4">Расписание не заполнено</h1>
                        )}

                        {calendar?.work_schedule?.length > 0 && (
                            <Row >
                                <Col className="text-center">
                                    <h3>
                                        {"Мастер работает с "}
                                        {DateTime.fromISO(calendar.work_schedule[0].start).toFormat("H:mm")}
                                        {" до "}
                                        {DateTime.fromISO(calendar.work_schedule.at(-1).end).toFormat("H:mm")}
                                    </h3>
                                </Col>
                            </Row>
                        )}

                        {records && (
                            <div className="calendar mt-2">
                                {records?.map((record, index) => (
                                    <div
                                        key={index}
                                        className={"item " + record.type + (record.start === recordDate ? " selected" : "")}
                                        onClick={() => handleCalendarClick(record)} >
                                        <Row className="p-2">
                                            <Col className="col-4 pe-0">
                                                <span className="period">
                                                    {DateTime.fromISO(record.start).toFormat("H:mm")}

                                                    {record.type === "break" && (
                                                        "—" + DateTime.fromISO(record.end).toFormat("H:mm")
                                                    )}

                                                    {record.type === "used" && (
                                                        "—" + DateTime.fromISO(record.end).toFormat("H:mm")
                                                    )}

                                                </span>
                                            </Col >
                                            <Col className="col-8">
                                                {record?.text}
                                            </Col >
                                        </Row>
                                    </div>
                                ))}
                            </div>
                        )}

                    </>
                ) : (
                    <Row className="vh-100 text-center">
                        <Col className="mt-5">
                            <Spinner animation="border" variant="secondary" />
                        </Col>
                    </Row>
                )}
            </Container >

            <ClientMainButton
                mainButtonText={recordDate ? "Записаться" : "Выберите дату и время"}
                disabled={!recordDate}
                handleMainButtonClick={handleSaveRecord}
            />

        </>
    )
};

export default ClientCalendar;
