// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar .navbar-nav a {
  text-decoration: underline;
  color: var(--theme-disabled-text);
}

.navbar .navbar-nav a.active {
  font-weight: normal;
  color: var(--theme-icon-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminMenu/Style.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,iCAAiC;AACnC;;AAEA;EACE,mBAAmB;EACnB,8BAA8B;AAChC","sourcesContent":[".navbar .navbar-nav a {\n  text-decoration: underline;\n  color: var(--theme-disabled-text);\n}\n\n.navbar .navbar-nav a.active {\n  font-weight: normal;\n  color: var(--theme-icon-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
