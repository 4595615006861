import { NavLink } from "react-router-dom";
import { Button, Navbar, Nav, Container } from "react-bootstrap";

import "./Style.css";

const handleMenuClick = () => {

    document.querySelectorAll("video").forEach((video) => {
        video.pause();
    });


}

const AdminMenu = (params) => {
    let { handleButtonClick, buttonText, title } = params;
    return (
        <Navbar expand="lg" className="mt-2 mb-2" >
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleMenuClick} />

                {title && (
                    <h1 style={{ width: "calc(100% - 55px)", paddingRight: "40px", margin: "0px" }}>{title}</h1>
                )}

                {buttonText && (
                    <Button variant="base" style={{ width: "calc(100% - 55px)" }} onClick={handleButtonClick}>{buttonText}</Button>
                )}

                {/* <NavLink className={"btn btn-base"} style={{ width: "calc(100% - 55px)" }} to={"/app/master/tenant/clients/"}>Записать клиента</NavLink> */}

                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">

                        <NavLink className={"nav-link  mt-2"} to={"/app/master/tenant/"} end >Журнал записей</NavLink>

                        <NavLink className={"nav-link"} to={"/app/master/tenant/settings/"} end >Настройки бота</NavLink>

                        {/* <NavLink className={"nav-link"} to={"/app/master/tenant/tariff/"} end >Тарифы</NavLink> */}

                        <NavLink className={"nav-link"} to={"/app/master/tenant/catalog/"} end >Мои услуги</NavLink>

                        <NavLink className={"nav-link"} to={"/app/master/tenant/clients/"} end >Мои клиенты</NavLink>

                        <NavLink className={"nav-link"} to={"/app/master/tenant/manual_mailings/"} end >Отправка сообщений клиентам</NavLink>

                        <NavLink className={"nav-link"} to={"/app/master/tenant/automatic_mailings/"} end >Автоматические уведомления</NavLink>
                        {/* <NavLink className={"nav-link"} to={"/app/master/tenant/automatic_mailings/"} end >Автоматические рассылки клиентам</NavLink> */}
                        {/* <NavLink className={"nav-link"} to={"/app/master/tenant/automatic_mailings_master/"} end >Автоматические уведомления мастеру</NavLink> */}

                        <NavLink className={"nav-link"} to={"/app/master/tenant/bot_button/"} end >Запись клиентов из Telegram канала</NavLink>
                        <NavLink className={"nav-link"} to={"/app/master/tenant/bot_link/"} end >Ссылка на бот для клиентов</NavLink>
                        <NavLink className={"nav-link"} to={"/app/master/tenant/help/"} end >Помощь</NavLink>

                    </Nav>

                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default AdminMenu;
