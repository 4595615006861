import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../App.js";
import { Container, Row, Col, Accordion, Spinner } from 'react-bootstrap';
import AdminPanel from "../../components/AdminPanel/AdminPanel.js";
import { useNavigate } from "react-router-dom";
import AdminMenu from "../../components/AdminMenu/AdminMenu.js";

const AdminHelp = (props) => {

  const [appData, setAppContext] = useContext(AppContext);

  const navigate = useNavigate();

  const [helpData, setHelpData] = useState(JSON.parse(localStorage.getItem(appData.guid + "_admin_faq_cash")));

  const [accordions, setAccordions] = useState([]);//helpData?.map((item) => item.code));

  useEffect(() => {
    const handleBackButton = () => {
      navigate(-1);
    }

    window?.Telegram.WebApp.BackButton.show();
    window?.Telegram.WebApp.BackButton.onClick(handleBackButton);

    return () => {
      window?.Telegram.WebApp.BackButton.hide();
      window?.Telegram.WebApp.BackButton.offClick(handleBackButton);
    };

  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios.get("/api/client_v1/template/faq/").then((result) => {
      setHelpData(result.data);
      localStorage.setItem(appData.guid + "_admin_faq_cash", JSON.stringify(result.data));
    });
  }, []);

  const handleAccordionClick = (code) => {
    if (accordions.includes(code)) {
      setAccordions(accordions.filter(item => item !== code))
    } else {
      setAccordions([...accordions, code])
    }

    document.querySelectorAll("video").forEach((video) => {
      video.pause();
    });

  }

  return (
    <>
      <AdminPanel />

      <AdminMenu title="Помощь" />

      <Container className="admin admin-help">



        {helpData ? (




          <Accordion
            className="mt-1 mb-5"
            activeKey={accordions}>
            {helpData?.map((item) => (

              item.template ?
                (<Accordion.Item key={item.code} eventKey={item.code} >
                  <Accordion.Header
                    onClick={() => handleAccordionClick(item.code)}
                  >
                    {item.name}
                  </Accordion.Header>
                  <Accordion.Body style={{ whiteSpace: "pre-wrap" }}>

                    {/* <video
                      type="video/mp4"
                      controls={true}
                      width="100%"
                      src="https://master.myzam.ru/files/1ee24830-57e5-4345-a27a-c5694af9eb04.mp4"

                    >
                    </video> */}

                    {item.template}

                    {/* https://www.npmjs.com/package/react-render-markup */}

                  </Accordion.Body>
                </Accordion.Item>
                ) : ("")
            ))}
          </Accordion>

        ) : (
          <Row className="vh-100 text-center">
            <Col className="mt-5">
              <Spinner animation="border" variant="secondary" />
            </Col>
          </Row>
        )
        }
      </Container >
    </>
  );
};

export default AdminHelp;
