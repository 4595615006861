// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.period-editor .btn-square {
  width: 2.3rem;
  height: 2.3rem;
  padding: 0rem !important;
}

.calendar .item.record.not_confirmed {
  border-color: var(--theme-calendar-short);
}

.calendar .status-name.created {
  display: none;
}

.calendar .status-name.not_confirmed {
  color: var(--theme-calendar-short);
}

.calendar .status-name.completed {
  color: var(--theme-calendar-free);
}

.admin-record-detail .client-pay {
  padding-top: 0.5rem;
}

.admin-record-detail .record-title {
  font-size: 1.125rem;
  text-align: center;
  font-weight: 500;
}

.admin-record-detail .record-name {
  font-size: 1rem;
  text-align: center;
  font-weight: 500;
}

.admin-record-detail .client-phone {
  padding: 0.55rem 0.75rem;
}

.admin-record-detail .records-box {
  padding: 0.5rem;
  border: 1px solid var(--theme-header-color);
  border-radius: 0.5rem;
}

.period-end {
  color: gray;
}
`, "",{"version":3,"sources":["webpack://./src/appMaster/AdminCalendar/Style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,wBAAwB;AAC1B;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,eAAe;EACf,2CAA2C;EAC3C,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".period-editor .btn-square {\n  width: 2.3rem;\n  height: 2.3rem;\n  padding: 0rem !important;\n}\n\n.calendar .item.record.not_confirmed {\n  border-color: var(--theme-calendar-short);\n}\n\n.calendar .status-name.created {\n  display: none;\n}\n\n.calendar .status-name.not_confirmed {\n  color: var(--theme-calendar-short);\n}\n\n.calendar .status-name.completed {\n  color: var(--theme-calendar-free);\n}\n\n.admin-record-detail .client-pay {\n  padding-top: 0.5rem;\n}\n\n.admin-record-detail .record-title {\n  font-size: 1.125rem;\n  text-align: center;\n  font-weight: 500;\n}\n\n.admin-record-detail .record-name {\n  font-size: 1rem;\n  text-align: center;\n  font-weight: 500;\n}\n\n.admin-record-detail .client-phone {\n  padding: 0.55rem 0.75rem;\n}\n\n.admin-record-detail .records-box {\n  padding: 0.5rem;\n  border: 1px solid var(--theme-header-color);\n  border-radius: 0.5rem;\n}\n\n.period-end {\n  color: gray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
