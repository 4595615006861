import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../App.js";
import { Container, Row, Col, Accordion, Spinner } from 'react-bootstrap';
import AdminPanel from "../../components/AdminPanel/AdminPanel.js";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import { LandingManicure, LandingHairdresser, LandingBrovist, LandingVisagiste, LandingCosmetologist, LandingMassage, LandingGroomer, LandingTelegram } from "./icons.js";
import { MyZamLogo } from "../../components/Icons.js";

import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/700.css";
import "./Style.css";

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import "./slider/style.css";
import { Zoom, Navigation, Pagination } from 'swiper/modules';


const Landing = (props) => {

  const [tariffs, setTariffs] = useState();
  const [faq, setFaq] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {

    const handleBackButton = () => {
      navigate(-1);
    }

    if (location?.state?.back) {

      window?.Telegram.WebApp.BackButton.show();
      window?.Telegram.WebApp.BackButton.onClick(handleBackButton);

      return () => {
        window?.Telegram.WebApp.BackButton.hide();
        window?.Telegram.WebApp.BackButton.offClick(handleBackButton);
      };
    }

  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios.get("/api/public_v1/tariffs/").then((result) => {
      setTariffs(result.data);
    });
  }, []);

  useEffect(() => {
    axios.get("/api/public_v1/landing_faq/").then((result) => {
      setFaq(result.data);

    });
  }, []);

  const handleStartRegistration = () => {
    window?.Telegram.WebApp.openTelegramLink("https://t.me/presentation_beauty_master_bot?start=registration");
    window?.Telegram.WebApp.close();
  }

  return (
    <Container className="container-fluid landing" >

      <Row className="step-1">
        <Col className="mt-5">
          <MyZamLogo />
          <h1 className="mt-4">Сервис для записи и привлечения клиентов</h1>
          <div className="img-step-1"></div>

          <h2 className="mt-4 mb-4">Создан специально для мастеров Beauty-индустрии
            <br></br><br></br>Автоматические напоминания клиентам, различные варианты записи и многое другое доступны даже на бесплатном тарифе!</h2>
          {/* <p className="mb-4">Клиенты видят в Telegram ваши услуги, график работы и самостоятельно записываются!</p>
          <a href="https://t.me/presentation_dev_bot" className="btn-yellow">Воспользоваться сервисом</a> */}
          {/* <h1 className="mt-5 mb-4">Кому подходит?</h1> */}
          {/* 
          <div className="for-whom">
            <LandingManicure />
            <span className="for-whom-text">Мастер маникюра
            </span>
          </div>

          <div className="for-whom">
            <LandingHairdresser />
            <span className="for-whom-text">Парикмахер
            </span>
          </div>

          <div className="for-whom">
            <LandingBrovist />
            <span className="for-whom-text">Бровист/ лэшмейкер
            </span>
          </div>

          <div className="for-whom">
            <LandingVisagiste />
            <span className="for-whom-text">Визажист
            </span>
          </div>

          <div className="for-whom">
            <LandingCosmetologist />
            <span className="for-whom-text">Косметолог
            </span>
          </div>

          <div className="for-whom">
            <LandingMassage />
            <span className="for-whom-text">Массажист
            </span>
          </div>

          <div className="for-whom mb-5">
            <LandingGroomer />
            <span className="for-whom-text">Грумер
            </span>
          </div> */}
        </Col>
      </Row>
      {/* 
      <Row className="step-2 mt-5">
        <Col >
          <h1>Как работает?</h1>
          <LandingTelegram className="d-block mt-4 mb-3" />
          <h4 className="mb-4">Вы подключаетесь к сервису Мой Зам и получаете собственное приложение-бот в Telegram, красивое, простое и понятное</h4>
          <Swiper
            modules={[Zoom, Pagination]}
            zoom={true}
            slidesPerView={'auto'}
            spaceBetween={20}
            pagination={{
              clickable: true,
            }}
            className="mb-4"
          >
            <SwiperSlide ><div className="slide"></div></SwiperSlide>
            <SwiperSlide><div className="slide"></div></SwiperSlide>
            <SwiperSlide><div className="slide"></div></SwiperSlide>
            <SwiperSlide><div className="slide"></div></SwiperSlide>
            <SwiperSlide><div className="slide"></div></SwiperSlide>
            <SwiperSlide><div className="slide"></div></SwiperSlide>
            <SwiperSlide><div className="slide"></div></SwiperSlide>
          </Swiper>
          <a className="btn-yellow" href="https://t.me/presentation_dev_bot">Воспользоваться сервисом</a>
        </Col>
      </Row> */}

      <Row className="step-3  mt-5">
        <Col >
          <h1>Что получите вы</h1>
          <div className="img-step-3"></div>
          <p className="type-1">Собственное приложение-бот в Telegram</p>
          {/* <p className="type-1">Улучшение посещаемости и плотности записи за счёт:</p> */}
          <p className="type-2">В нем заполняете прайс-лист и указываете часы работы.</p>
          <p className="type-2">Клиенты переходят в ваш бот и самостоятельно записываются на свободное время.</p>
          <p className="type-2">При необходимости вы можете записать клиента вручную или отменить его запись.</p>

          <p className="type-1">Запись из вашего Telegram канала</p>
          <p className="type-2">Бот поможет создать кнопку для записи и закрепить ее в канале.</p>
          <p className="type-2">Клиенты будут записываться прямо из вашего Telegram канала!</p>

          <p className="type-1">Автоматические уведомления клиентов</p>
          <p className="type-2">Бот напомнит клиенту о записи и попросит ее подтвердить.<br></br>
            Покажет вам, кто подтвердил визит и кто этого не сделал.</p>
          <p className="type-2">После визита поблагодарит клиента и предложит записаться снова.</p>
          <p className="type-2">Клиент давно не приходил? Бот напомнит о вас.</p>
          <p className="type-2">Все рассылки бесплатные!</p>

          <p className="type-1">Журнал записей и база клиентов</p>
          <p className="type-2">Информация о записях, свободном и занятом времени, теперь в вашем телефоне и всегда у вас под рукой.</p>
          <p className="type-2">Контакты клиентов и история их посещения сохраняются автоматически.</p>
          <p className="type-2">Все данные надежно хранятся, не видны другим мастерам и никуда не передаются!</p>

          <p className="type-1">Поиск новых клиентов</p>
          <p className="type-2">Можно привлекать клиентов бесплатно, разместив ссылки на свое приложение-бот:</p>
          <p className="type-2-1"> - на картах Яндекс, 2ГИС, Гугл</p>
          <p className="type-2-1"> - в группах вашего ЖК, района или города</p>

          <p className="type-1">Реферальная программа</p>
          <p className="type-2">Предложите клиентам скидку-бонус за рекомендации вас друзьям.</p>
          <p className="type-2">Бот автоматически определит, кто из клиентов привлек друга, и начислит этому клиенту бонус.</p>

          <p className="type-1">Программа лояльности</p>
          <p className="type-2">Удерживайте клиентов с помощью кэшбэк-бонусов.<br></br>
            Срок жизни бонусов можно ограничить, так у клиентов будет стимул записываться к вам регулярно.</p>
          <p className="type-2">Настройки программы лояльности делаете вы, все начисления и расчеты делает бот.</p>

          <h1 className="mt-5">Преимущества</h1>
          <p className="type-1">Легко подключиться и начать работать</p>
          <p className="type-1">Выглядит как приложение, но не требует установки</p>
          <p className="type-1">Может записывать клиентов прямо из вашего Telegram-канала</p>
          <p className="type-1">Отправляет клиентам напоминания - все рассылки бесплатные!</p>
          <p className="type-1 mb-5">Различные варианты записи, рассылки, возможности для привлечение клиентов и другой функционал облегчает работу, экономит ваше время, помогает зарабатывать больше</p>
          <p className="btn-yellow mb-5" onClick={handleStartRegistration}>Подключиться</p>

        </Col>
      </Row>

      <Row className="step-4">
        <Col >
          <h1 className="mt-5 mb-4">Тарифы</h1>
          <div className="img-step-4 mb-3"></div>
          {/* <h2>Все возможности сервиса в первые 14 дней бесплатно!</h2> */}
          {/* <p className="type-3">Далее выбирайте необходимый для себя функционал и тариф</p> */}
          <p className="type-3  mb-4">Всего два тарифных плана, простых и без скрытых платежей</p>

          {/* <p className="type-4 mt-1 mb-4"> Тариф можно менять</p> */}
          <Swiper
            modules={[Zoom, Pagination]}
            zoom={true}
            slidesPerView={'auto'}
            pagination={{
              clickable: true,
            }}
          >
            {tariffs?.map((item) => (
              <SwiperSlide >
                <div className={"slide-price slide-type-" + item.code}>
                  <div className="slide-header">
                    <div className="slide-header-title">{item.name}</div>
                    <div className="slide-header-price">{item.price === 0 ? "бесплатно" : (item.price + " руб./мес.")}</div>

                  </div>
                  <div className="slide-options">
                    {item?.options?.map((option) => (
                      <p className={"slide-option-" + option.active}>{option.name}</p>
                    ))}
                    <div className="slide-option-true"><b>{item.description}</b></div>
                  </div>

                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <p className="btn-yellow mt-3 mb-5" onClick={handleStartRegistration}>Подключиться</p>

          {/* <a href="https://t.me/presentation_dev_bot?start=presentation_bot_landing_2" className="btn-yellow mt-3 mb-5" onClick={handleCloseWebApp}>Подключиться</a> */}
        </Col>
      </Row>

      <Row className="step-5 mt-5">
        <Col>
          <h1 className="mb-3">Как подключиться к Мой Зам?</h1>
          <div className="img-step-5 mb-3"></div>
          <h2 className="mb-3">Сделать это просто, вам потребуется всего несколько минут</h2>
          <p className="type-5">Для подключения нужно зарегистрировать свой бот в Telegram и привязать его к сервису Мой Зам</p>
          <p className="type-6 mt-3 mb-4">В процессе подключения вы увидите подсказки</p>
          {/* <a href="https://t.me/presentation_dev_bot?start=presentation_bot_landing_3" className="btn-yellow" onClick={handleCloseWebApp}>К подключению</a> */}
          <p className="btn-yellow" onClick={handleStartRegistration}>Подключиться</p>

        </Col>
      </Row>

      <Row className="step-6 mt-5">
        <Col>
          <h1>Вопросы-ответы</h1>
          <Accordion className="faq" >
            {faq?.map((item, index) => (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header><span>{item.name}</span></Accordion.Header>
                <Accordion.Body >{item.template}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>

      <Row className="step-7 mt-5">
        <Col>
          <h1>Остались ещё вопросы? Напишите нам!</h1>
          <div className="img-step-7"></div>
          <a href="https://t.me/MyZamSupport" className="btn-frame mb-5">Отправить сообщение</a>
        </Col>
      </Row>

      <Row className="footer">
        <Col>
          <MyZamLogo className="logo" />
          <NavLink className="navigate" to={"/app/doc/privacy-policy/"} end >Политика конфиденциальности</NavLink>
          <NavLink className="navigate" to={"/app/doc/offer-agreement/"} end >Договор оферты</NavLink>
          <NavLink className="navigate" to={"/app/doc/contacts/"} end >Контакты</NavLink>
          <div className="copyright">© 2024 Мой Зам</div>
        </Col>
      </Row>

    </Container >
  );
};

export default Landing;
