import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../App.js";
import { Container, Row, Col, Accordion, Spinner } from 'react-bootstrap';
import AdminPanel from "../../components/AdminPanel/AdminPanel.js";
import ClientMenu from "../../components/ClientMenu/ClientMenu.js";
import ClientMainButton from "../../components/ClientMainButton/ClientMainButton.js";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import { IconCheck } from "../../components/Icons.js";
import CatalogItem from "../../components/CatalogItem/CatalogItem.js";
import metadata from './../../../package.json';

const ClientCatalog = (props) => {

  const [appData, setAppContext] = useContext(AppContext);
  const navigate = useNavigate();

  //Кешировать каталог для визуального ускорения загрузки
  const catalogCash = true;

  const [catalog, setCatalog] = useState(catalogCash ? JSON.parse(localStorage.getItem(appData.guid + "_catalog_client_cash")) : null);

  const [accordions, setAccordions] = useState(catalog?.parents?.map((parent) => parent.guid));

  useEffect(() => {
    window.scrollTo(0, 0);
    axios.get("/api/client_v1/catalog/").then((result) => {
      setCatalog(result.data);
      if (catalogCash) {
        localStorage.setItem(appData.guid + "_catalog_client_cash", JSON.stringify(result.data));
      }
    });
  }, []);


  // useEffect(() => {
  //   console.log("ClientCatalog appData", appData);
  // }, [appData]);


  useEffect(() => {
    setAccordions(catalog?.parents?.map((parent) => parent.guid));
  }, [catalog]);


  const handleAccordionClick = (guid) => {
    if (guid) {
      if (accordions.includes(guid)) {
        setAccordions(accordions.filter(item => item !== guid))
      } else {
        setAccordions([...accordions, guid])
      }
    }
  }

  const handleItemClick = (guid) => {

    let basketItems = appData?.basket?.items ? appData?.basket?.items : [];

    if (guid) {
      if (basketItems.includes(guid)) {
        basketItems = basketItems.filter(item => item !== guid);
      } else {
        basketItems.push(guid);
      }
    }


    let basket = { ...appData?.basket, items: basketItems, count: 0, price: 0, duration: 0 };
    catalog.items.forEach((catalogItem) => {
      basketItems.forEach((basketItem) => {
        if (catalogItem.guid === basketItem) {
          basket.price += catalogItem.price;
          basket.duration += catalogItem.duration;
        }
      });
    });
    basket.count = basketItems.length;

    setAppContext({ ...appData, "basket": basket });

  }

  return (
    <>

      <AdminPanel />

      <Container className="mt-3 client" >
        <ClientMenu />
        <h1 className="mt-4 mb-4">Выберите услугу</h1>
        {catalog ? (
          <>
            <Accordion
              className="mb-3"
              activeKey={accordions}>

              {catalog.parents?.map((parent) => (
                <Accordion.Item key={parent.guid} eventKey={parent.guid} >

                  <Accordion.Header
                    onClick={() => handleAccordionClick(parent.guid)}
                  >
                    {parent.name}
                  </Accordion.Header>

                  <Accordion.Body>

                    <div className="catalog" >
                      {catalog.items?.map((item, index) => {
                        if (item.parent_guid === parent.guid) {
                          return (
                            <CatalogItem
                              key={item.guid}
                              item={item}
                              icon={appData?.basket?.items?.includes(item.guid) ? <IconCheck /> : ""}
                              handleItemClick={() => handleItemClick(item.guid)}
                              className={"mb-3"}
                            />
                          )
                        } else { return ""; }
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}

            </Accordion>


            <Row className="text-center">
              <Col className="mb-3">
                <NavLink className="about" to={"/app/landing/"} state={{ back: true }} end >Работает на Мой Зам</NavLink>
                <p className="version">{`v${metadata.version}`} - {appData?.server_version}</p>
              </Col>
            </Row>
          </>

        )
          :
          (
            <Row className="vh-100 text-center">
              <Col className="mt-5">
                <Spinner animation="border" variant="secondary" />
              </Col>
            </Row>
          )
        }



      </Container >

      <ClientMainButton mainButtonText={"Выберите дату и время"} disabled={false} handleMainButtonClick={() => navigate("/app/master/client/calendar/")} />

    </>
  );
};

export default ClientCatalog;
