import axios from "axios";

export const GetTemplate = (handleTemplate, code, ...param) => {

    // const getData = async () => {
    //     const response = await axios.get(
    //         `https://famous-quotes4.p.rapidapi.com/random`
    //     );
    // };
    console.log("GetTemplate", code);


    axios.get("/api/client_v1/template/" + code + "/")
        .then((result) => {
            let template = result.data.template;
            param.forEach((value, index) => {
                template = template.replace("%" + (index + 1), value);
            });
            if (template) {
                handleTemplate(template);
            }
        }).catch(() => handleTemplate("Ошибка сервера, попробуйте позже..."));

    //let itemCode = "template_" + code;
    //let template = sessionStorage.getItem(itemCode);

    //if (template) {
    //    handle(template);
    //} else {
    // axios.get("/api/client_v1/template/" + code + "/").then((result) => {
    //     let template = result.data.template;

    //     param.forEach((value, index) => {
    //         template = template.replace("%" + (index + 1), value);
    //     });

    //     handle(template);
    //     //sessionStorage.setItem(itemCode, JSON.stringify(result.data.template));
    // });
    //}

}
