//import { useContext } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import './Style.css';
//import { AppContext } from "../../App.js";

const DialigConfirm = (props) => {

    //    const [appData] = useContext(AppContext);

    return (
        <footer className={"fixed-bottom text-center p-2 dialig-confirm"} style={{ "transform": props?.show ? "scaleY(1)" : "scaleY(0)" }} >
            <Row>
                <Col><Button onClick={props.handleCancel} variant="base" className="w-100">Отменить</Button></Col>
                <Col><Button onClick={props.handleYes} variant="main" className="w-100">Сохранить</Button></Col>
            </Row>
        </footer >
    );
};

export default DialigConfirm;