import axios from "axios";
import { useEffect, useState } from "react";
import DialigConfirm from "../../components/DialigConfirm/DialigConfirm.js";
import { useFieldArray, useForm } from "react-hook-form";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { IconMinus, IconPlus } from "../../components/Icons.js";
import { DateTime } from 'luxon'
import { IsoNoZone } from "../../App.js";
import { GetTemplate } from "../../components/DialogTemplates/GetTemplate.js";
import "./Style.css";

const PeriodEditor = (props) => {

    const { calendar, handleUpdateCalendarDate } = props;

    const [selectOptions, setSelectOptions] = useState([]);
    const [submitVisible, setSubmitVisible] = useState(false);

    const defaultFildValue = { start: '', end: '' };

    const { register, control, handleSubmit, reset, } = useForm();

    const { fields, append, remove } = useFieldArray({
        name: "periods",
        control: control
    });


    useEffect(() => {
        setSubmitVisible(false);
        if (calendar) {
            let options = [];

            let dateStart = DateTime.fromISO(calendar?.date);
            for (let minutes = 0; minutes <= 60 * 24; minutes += 30 /* calendar?.period*/) {
                let time = dateStart.plus({ minutes: minutes });
                let text = time.toFormat("H:mm");

                if (minutes === 60 * 24) {
                    text = "24:00"
                }

                options.push({ value: time.toFormat(IsoNoZone), text: text });
            }

            setSelectOptions(options);
            reset({ periods: calendar?.work_schedule.length > 0 ? calendar?.work_schedule : [defaultFildValue] });
        }
    }, [calendar?.date])

    const handleChange = () => {
        setSubmitVisible(true);
    }

    const onSave = (data) => {

        let start_period = DateTime.now().startOf('day');

        for (let element of data.periods) {
            let time_start = DateTime.fromISO(element.start);
            let time_end = DateTime.fromISO(element.end)

            if ((start_period > time_start) || (time_start > time_end)) {
                GetTemplate(
                    (template) => window?.Telegram?.WebApp.showAlert(template),
                    "DialogErrorPeriodDate"
                );
                return false;
            }
            start_period = time_end;
        };

        setSubmitVisible(false);
        axios.patch("/api/tenant_v1/calendar/" + calendar.date + "/", data
        ).then((result) => {
            if (result?.data?.error) {
                setSubmitVisible(true);
                window.Telegram.WebApp.showAlert(result?.data?.error);
            } else {
                handleUpdateCalendarDate(DateTime.fromISO(result.data.date));
            }
        });

    }

    return (
        <Form onChange={handleChange}  >
            <Row>
                <Col className="text-center ">
                    <h1>{calendar?.work_schedule.length > 0 ? "Изменить рабочее время" : "Добавить рабочее время"}</h1>
                </Col>
            </Row>
            {fields?.map((period, index) => (
                <Row key={index} className="mb-2">
                    <Col className="col-9 offset-1 ps-0 pe-0 text-center ">
                        c{" "}
                        <Form.Select className={"d-inline"}
                            {...register("periods[" + index + "].start", { required: true })}
                            style={{ width: "max-content" }} >
                            {selectOptions?.map((item, index) => (
                                <option key={index} value={item.value}>{item.text}</option>
                            ))}
                        </Form.Select>
                        {" "} до{" "}
                        <Form.Select className={"d-inline "}
                            {...register("periods[" + index + "].end", { required: true })}
                            style={{ width: "max-content" }}>
                            {selectOptions?.map((item, index) => (
                                <option key={index} value={item.value}>{item.text}</option>
                            ))}
                        </Form.Select>
                        {" "}
                    </Col>
                    <Col className="col-2 text-end  ps-0">
                        <Button variant={"outline"} className="btn-square" onClick={() => { remove(index); handleChange(); }}><IconMinus /></Button>
                    </Col>
                </Row>
            ))}

            <Row className="mb-2 text-end">
                <Col className="col-2 offset-10 ps-0" >
                    <Button variant={"outline"} className="btn-square" onClick={() => append(defaultFildValue)}><IconPlus /></Button>
                </Col>
            </Row>

            <DialigConfirm show={submitVisible} handleYes={handleSubmit(onSave)} handleCancel={props.handleCancel} />

        </Form>
    )
};

export default PeriodEditor;
