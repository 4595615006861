import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Spinner, Button } from 'react-bootstrap';
//import AdminCalendarList from "./AdminCalendarList.js";
//import AdminClientRecord from "./AdminClientRecord.js";
import { DateTime } from 'luxon'
import { AppContext, StatusName } from "../../App.js";
import { PopUpAlert } from "../../components/DialogTemplates/PopUpAlert.js";
import AdminPanel from "../../components/AdminPanel/AdminPanel.js";
import AdminMenu from "../../components/AdminMenu/AdminMenu.js";
import CalendarHeader from "../../components/CalendarHeader/CalendarHeader.js";
import { useNavigate, useParams } from "react-router-dom";
import AdminPeriodEditor from "./AdminPeriodEditor.js";
import "./Style.css";
import "./Icons.css";

const AdminCalendar = (props) => {

  const params = useParams();
  const [appData] = useContext(AppContext);
  const navigate = useNavigate();

  const [showPeriodEditor, setShowPeriodEditor] = useState(false);
  const [calendarRecords, setCalendarRecords] = useState();
  const [calendar, setCalendar] = useState();

  const [calendarDate, setCalendarDate] = useState(
    params?.date ?
      DateTime.fromISO(params?.date)
      :
      DateTime.now().plus({ milliseconds: appData.time_zone_delta })
  );


  useEffect(() => {
    const controller = new AbortController();

    if (calendarDate) {
      //setCalendar(false);
      axios.get("/api/tenant_v1/calendar/" + calendarDate.toFormat("yyyy-MM-dd") + "/", {
        signal: controller.signal
      }).then((result) => {
        setCalendar(result.data);
        setCalendarRecords(PeriodsToRecords(result.data));
      });
    }

    return () => {
      controller.abort();
    };
  }, [calendarDate]);

  function PeriodsToRecords(calendar) {

    let key = 0;
    let result = [];

    setShowPeriodEditor(calendar?.work_schedule?.length === 0);

    if (calendar?.client_records && calendar?.work_schedule.length > 0) {

      calendar?.work_schedule.forEach((period, index) => {

        //Заполняем перерывы
        if (calendar?.work_schedule[index + 1]?.start) {

          let startTime = DateTime.fromISO(period.end);
          let endTime = DateTime.fromISO(calendar.work_schedule[index + 1].start);

          result.push({
            start: startTime,
            end: endTime,
            type: "break",
            key: key
          });

        }
      });

      //Добавляем записи клиентов
      calendar?.client_records.forEach(period => {

        let startTime = DateTime.fromISO(period.start);
        let endTime = DateTime.fromISO(period.end);

        result.push({
          client_record: period,
          start: startTime,
          end: endTime,
          periods: [],
          type: "record",
        });

      });

      //меняем направление с низу вверх
      result = result.sort((a, b) => b.start - a.start);

      //Добавляем расписание мастера
      calendar?.work_schedule.forEach((period, index) => {

        let startTime = DateTime.fromISO(period.start);
        let endTime = DateTime.fromISO(period.end);



        for (let time = startTime; time < endTime; time = time.plus({ minutes: calendar?.period })) {

          let check = true;
          let workPeriodStart = time;
          let workPeriodStandartEnd = time.plus({ minutes: calendar?.period });
          let workPeriodEnd = workPeriodStandartEnd > endTime ? endTime : workPeriodStandartEnd;

          //console.log("workPeriodStart", workPeriodStart.toISOTime());

          //проверяем плановый период на занятость клиентом
          result.forEach((usedPeriod, usedIndex) => {
            //for (const [usedIndex, usedPeriod] of result.entries()) {

            if (usedPeriod.type === "record") {

              // 1) Запись накладывается на конец периода
              if (usedPeriod.start > workPeriodStart && usedPeriod.start < workPeriodEnd) {
                check = true; //период добавляем
                result[usedIndex].periods.push({ "start": result[usedIndex].start, "end": workPeriodEnd, "type": "1" }); //в запись мастеру добавляем период 
                workPeriodEnd = usedPeriod.start;
                //console.log("1) Запись накладывается на конец периода", usedPeriod);
              }

              // 2) Период полностью входит в запись
              if (usedPeriod.start <= workPeriodStart && usedPeriod.end >= workPeriodEnd) {
                check = false; //период добавляем
                result[usedIndex].periods.push({ "start": workPeriodStart, "end": workPeriodEnd, "type": 2 }); //в запись мастеру добавляем период 
                //console.log("2) Период полностью входит в запись", usedPeriod);
              }

              // 3) Запись накладывается на начало периода
              if (usedPeriod.start <= workPeriodStart && usedPeriod.end > workPeriodStart && usedPeriod.end < workPeriodEnd) {
                check = false; //период не добавляем
                result[usedIndex].periods.push({ "start": workPeriodStart, "end": workPeriodEnd, "type": 3 }); //в запись мастеру добавляем период 
                //console.log("3) Запись накладывается на начало периода", usedPeriod);
              }

            }

          });

          if (check && workPeriodStart !== workPeriodEnd) {

            let durationMinutes = workPeriodEnd.diff(workPeriodStart, "minutes");
            let short = durationMinutes.minutes < calendar.period ? "short" : "";

            result.push({
              start: workPeriodStart,
              end: workPeriodEnd,
              type: "free",
              short: short
            });

          };

        }
      });

      return result.sort((a, b) => a.start - b.start);

    } else {
      return [];
    }

  };

  return (
    <>

      <AdminPanel />
      <AdminMenu buttonText="Записать клиента" handleButtonClick={() => navigate("/app/master/tenant/clients/")} />

      <Container className="admin mt-2">

        <CalendarHeader calendarDate={calendarDate} handleUpdateCalendarDate={setCalendarDate} />

        {calendar?.work_schedule?.length > 0 && (
          <Row >
            <Col className="text-center">
              <h3>
                {"Мастер работает с "}
                {DateTime.fromISO(calendar.work_schedule[0].start).toFormat("H:mm")}
                {" до "}
                {DateTime.fromISO(calendar.work_schedule.at(-1).end).toFormat("H:mm")}
              </h3>
            </Col>
          </Row>
        )}

        <div className="calendar mt-2">
          <div>
            {/* {"1 -	Запись накладывается на конец периода"} <br />
            {"2 -	Период полностью входит в запись"} <br />
            {"3 -	Запись накладывается на начало периода"} <br /> */}


          </div >
          {calendarRecords?.map((record, index) => (
            <div
              className={"item " + record.type + " " + (record?.short ? record?.short : "") + " " + (record?.client_record?.status ? record?.client_record?.status : "")}
              key={index}
            >

              <Row className="p-2">
                <Col className="col-4 pe-0"
                  onClick={() => (record?.client_record?.guid ? navigate("/app/master/tenant/record/" + record?.client_record.guid + "/") : false)}
                >

                  {record?.periods?.length > 0 ? (
                    record?.periods.map((time, index2) =>
                    (<span key={index2} className="period">
                      {/* {time.type}; */}
                      {DateTime.fromISO(time.start).toFormat("H:mm")}—{DateTime.fromISO(time.end).toFormat("H:mm")}</span>)
                    )
                  ) : (
                    <span className="period">{DateTime.fromISO(record.start).toFormat("H:mm")}—{DateTime.fromISO(record.end).toFormat("H:mm")}</span>
                  )}


                </Col >
                <Col className="col-6 ps-1 pe-0"
                  onClick={() => (record?.client_record?.guid ? navigate("/app/master/tenant/record/" + record?.client_record.guid + "/") : false)}
                >
                  {/* {record?.type === "free" && (
                    <span className="period-status"
                      onClick={() => PopUpAlert('PopUpRecordFree')}
                    >Свободно</span>
                  )} */}

                  {record?.type === "free" && (
                    <span className="period-status"
                      onClick={() => PopUpAlert('PopUpRecordFree' + (record?.short ? record?.short : ""))}
                    >Свободно</span>
                  )}

                  {record?.type === "break" && (
                    <span className="period-status"
                      onClick={() => PopUpAlert('PopUpRecordBreak')}
                    >Перерыв</span>
                  )}

                  {record?.type === "record" && (
                    <>
                      <span className="client-name">{record?.client_record.client}</span>
                      <div>
                        {DateTime.fromISO(record.client_record.start).toFormat("H:mm")}
                        &nbsp;-&nbsp;
                        {DateTime.fromISO(record.client_record.end).toFormat("H:mm")}
                      </div>

                      {calendar?.client_records_detail?.map((detail, index3) => (
                        record?.client_record.guid === detail.guid && (
                          <div key={index3} className={"detail"}>
                            {detail.name}
                            {/* {detail.price}₽ */}
                          </div>
                        )
                      ))}

                      {record?.client_record.status &&
                        (<span className={'pt-1 status-name ' + record?.client_record.status} >
                          {StatusName[record?.client_record.status]}
                        </span>)
                      }

                    </>
                  )}
                </Col >
                <Col className="col-2 text-end">
                  {record?.client_record?.status &&
                    (<span className={'status-icon ' + record.client_record.status}
                      onClick={() => PopUpAlert('PopUpRecordStatus-' + record?.client_record.status)}
                    ></span>)
                  }
                </Col>
              </Row >
            </div>
          ))}
        </div >

        {
          calendar ? (
            calendarDate >= DateTime.now().plus({ milliseconds: appData.time_zone_delta }).startOf('day') && (
              <Row className="text-center period-editor">
                <Col>
                  {
                    showPeriodEditor ? (
                      <AdminPeriodEditor calendar={calendar} handleCancel={() => setShowPeriodEditor(false)} handleUpdateCalendarDate={setCalendarDate} />
                    ) : (
                      <Button variant={"outline"} className="mt-3" onClick={() => setShowPeriodEditor(true)}>Редактировать часы работы</Button>
                    )}
                </Col >
              </Row >
            )
          ) : (
            <Row className="vh-100 text-center">
              <Col className="mt-5">
                <Spinner animation="border" variant="secondary" />
              </Col>
            </Row>
          )}

      </Container >
    </>
  );
};

export default AdminCalendar;

