import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { timeFormat } from "../../components/tools.js";
import CalendarHeader from "../../components/CalendarHeader/CalendarHeader.js";
import { useNavigate } from "react-router-dom";
import { DateTime } from 'luxon';
import AdminPanel from "../../components/AdminPanel/AdminPanel";
import ClientMenu from "../../components/ClientMenu/ClientMenu";
import ClientMainButton from "../../components/ClientMainButton/ClientMainButton.js";
import { GetTemplate } from "../../components/DialogTemplates/GetTemplate.js";
import { PopUpAlert } from "../../components/DialogTemplates/PopUpAlert.js";
import { AppContext } from "../../App.js";

const ClientCalendar = (props) => {

    const [appData, setAppContext] = useContext(AppContext);

    const navigate = useNavigate();
    const [records, setRecords] = useState();
    const [calendar, setCalendar] = useState();
    const [recordDate, setRecordDate] = useState(false);

    const DateTimeServerNow = () => {
        return DateTime.now().plus({ milliseconds: appData.time_zone_delta });
    }

    const [calendarDate, setCalendarDate] = useState(DateTimeServerNow());

    useEffect(() => {

        const handleBackButton = () => {
            navigate(-1);
        }

        window?.Telegram.WebApp.BackButton.show();
        window?.Telegram.WebApp.BackButton.onClick(handleBackButton);

        return () => {
            window?.Telegram.WebApp.BackButton.hide();
            window?.Telegram.WebApp.BackButton.offClick(handleBackButton);
        };

    }, []);

    const handleCalendar = (date) => {
        setRecordDate(false);
        setCalendarDate(date);
    };

    const handleSaveRecord = (item) => {

        axios.post("/api/client_v1/records/", {
            data: { record: { ...appData.basket, start: recordDate }, client_guid: appData?.basket?.selected_client?.guid }
        }).then((result) => {
            if (result?.data?.error) {
                window.Telegram.WebApp.showAlert(result?.data?.error);
                setCalendar(result.data);
            } else {
                //Запись прошла успешно
                let link;
                let timeText = recordDate.setLocale("ru").toFormat("d MMMM на H:mm");

                if (appData?.basket?.selected_client?.guid) {
                    //msgText = "Клиент: " + appData.basket?.selected_client.name + " записан " + timeText;
                    PopUpAlert("PopUpCalendarStatus-recorded-by-master", timeText, appData.basket?.selected_client.name);

                    link = "/app/master/tenant/calendar/" + recordDate.toISODate() + "/";
                } else {

                    PopUpAlert("PopUpCalendarStatus-recorded", timeText);
                    link = "/app/master/client/records/";
                }

                setAppContext({ ...appData, "basket": {} });
                navigate(link);

            }
        });
    };

    useEffect(() => {
        const controller = new AbortController();

        if (calendarDate) {
            setCalendar(false);
            axios.get("/api/client_v1/calendar/" + calendarDate.toFormat("yyyy-MM-dd") + "/", {
                signal: controller.signal
            }).then((result) => {
                setCalendar(result.data);
            });
        }

        return () => {
            controller.abort();
        };
    }, [calendarDate]);


    useEffect(() => {

        if (calendar?.client_records && calendar?.work_schedule.length > 0) {

            let result = [];

            //Заполняем перерывы
            calendar?.work_schedule.forEach((period, index) => {

                if (calendar?.work_schedule[index + 1]?.start) {

                    let startTime = DateTime.fromISO(period.end);
                    let endTime = DateTime.fromISO(calendar.work_schedule[index + 1].start);

                    result.push({
                        start: startTime,
                        end: endTime,
                        type: "break",
                        text: "Перерыв у мастера"
                    });

                }
            });

            //Добавляем накладываем записи клиентов
            calendar?.client_records.forEach((period, index) => {

                let startTime = DateTime.fromISO(period.start);
                let endTime = DateTime.fromISO(period.end);

                result.push({
                    start: startTime,
                    end: endTime,
                    type: "used",
                    text: "Занято"
                });

            });

            // сортируем и реиндексируем
            //result = result.sort((a, b) => a.start - b.start);

            //меняем направление с низу вверх
            result = result.sort((a, b) => b.start - a.start);

            //Сверху накладываем расписание мастера
            calendar?.work_schedule.forEach((period, index) => {

                let startTime = DateTime.fromISO(period.start);
                let endTime = DateTime.fromISO(period.end);

                for (let time = startTime; time < endTime; time = time.plus({ minutes: calendar?.period })) {

                    let check = true;
                    let workPeriodStart = time;
                    let workPeriodStandartEnd = time.plus({ minutes: calendar?.period });
                    let workPeriodEnd = workPeriodStandartEnd > endTime ? endTime : workPeriodStandartEnd;


                    //проверяем плановый период на занятость клиентом
                    result.forEach((usedPeriod, usedIndex) => {

                        if (usedPeriod.type === "used") {
                            //console.log("0");
                            // 1) Запись накладывается на конец периода
                            if (usedPeriod.start > workPeriodStart && usedPeriod.start < workPeriodEnd) {
                                check = true; //период добавляем
                                result[usedIndex].fullEnd = workPeriodEnd;
                                workPeriodEnd = usedPeriod.start;
                                //console.log("1", usedPeriod);
                            }

                            // 2) Период полностью входит в запись
                            if (usedPeriod.start <= workPeriodStart && usedPeriod.end >= workPeriodEnd) {
                                check = false; //период добавляем
                                //console.log("2", usedPeriod);
                                result[usedIndex].fullEnd = workPeriodEnd;
                            }

                            // 3) Запись накладывается на начало периода
                            if (usedPeriod.start <= workPeriodStart && usedPeriod.end > workPeriodStart && usedPeriod.end < workPeriodEnd) {
                                check = false; //период не добавляем
                                //console.log("3", usedPeriod);
                                result[usedIndex].fullEnd = workPeriodEnd;
                            }

                        }
                    });


                    if (check) {
                        result.push({
                            start: workPeriodStart,
                            end: workPeriodEnd,
                            type: "plan",
                            text: "Свободно",
                        });
                    }
                }
            });

            // сортируем и реиндексируем
            let result_sort = result.sort((a, b) => a.start - b.start);

            // console.log("result_sort1", result_sort);

            // //группируем занятые периоды
            // var result_group = [];
            // let used_index_old = false;
            // result_sort.forEach((period, index) => {

            //     if (period.type === "used") {

            //         if (used_index_old === false) {
            //             console.log("period", period);
            //             used_index_old = result_group.push(period);
            //         } else {
            //             result_group[used_index_old - 1].end = period.end;
            //             result_group[used_index_old - 1].fullEnd = period?.fullEnd;
            //         }
            //     } else {
            //         result_group.push(period);
            //         used_index_old = false;
            //     }

            // });
            // console.log("result_sort2", result_sort);
            // result_sort = result_group;

            //поиск подходящих периодов
            result_sort.forEach((period, index) => {
                //ищем подходящий период
                if (period.type === "plan") {

                    let endCheckTime = period.start.plus({ minutes: appData.basket.duration });
                    let check = false;
                    let fullPeriodEnd = 0;


                    for (let index_next = index; index_next < result_sort.length; index_next++) {

                        if (result_sort[index_next].type !== "plan") {
                            check = false;
                            break;
                        }

                        fullPeriodEnd = result_sort[index_next].end;

                        if (result_sort[index_next].end >= endCheckTime) {
                            check = true;
                            break;
                        }

                    }

                    if (check) {
                        result_sort[index].fullEnd = fullPeriodEnd;
                        result_sort[index].type = "free";
                        result_sort[index].text = "Cвободно";
                    } else {
                        result_sort[index].fullEnd = fullPeriodEnd;
                        result_sort[index].type = "short";
                        result_sort[index].text = "Частично свободно";
                    }
                }

            });

            let masterTimeNow = DateTimeServerNow();

            setRecords(result_sort.filter(val => (val.start > masterTimeNow)));

        } else {
            setRecords();
        }

    }, [calendar]);


    const handleCalendarClick = (record) => {

        let msgCode = record?.type;

        if (record.start < DateTimeServerNow()) {
            msgCode = "offside";
        }

        if (msgCode) {

            let duration = (record.fullEnd - record.start) / 60 / 1000;

            GetTemplate((template) =>
                window?.Telegram?.WebApp.showAlert(template),
                "PopUpCalendarStatus-" + msgCode,
                timeFormat(duration)
            );

        }

        if (record.type === "free") {
            setRecordDate(record.start);
        }

        if (record.type === "short") {
            setRecordDate();
        }

    }

    return (
        <>

            <AdminPanel />

            <Container className="mt-3 client" style={{ marginBottom: "110px" }}>
                <ClientMenu />

                <Row >
                    <Col className="text-center"><h1 className="mt-3 mb-3">Выберите дату и время</h1></Col>
                </Row>

                <CalendarHeader calendarMinDate={DateTimeServerNow()} calendarDate={calendarDate} handleUpdateCalendarDate={handleCalendar} />

                {calendar ? (
                    <>
                        {calendar?.work_schedule?.length === 0 && (
                            <h1 className="mt-4 mb-4">Расписание не заполнено</h1>
                        )}

                        {calendar?.work_schedule?.length > 0 && (
                            <Row >
                                <Col className="text-center">
                                    <h3>
                                        {"Мастер работает с "}
                                        {DateTime.fromISO(calendar.work_schedule[0].start).toFormat("H:mm")}
                                        {" до "}
                                        {DateTime.fromISO(calendar.work_schedule.at(-1).end).toFormat("H:mm")}
                                    </h3>
                                </Col>
                            </Row>
                        )}

                        {records && (
                            <div className="calendar mt-2">
                                {records?.map((record, index) => (
                                    <div
                                        key={index}
                                        className={"item " + record.type + (record.start === recordDate ? " selected" : "")}
                                        onClick={() => handleCalendarClick(record)} >
                                        <Row className="p-2">
                                            <Col className="col-4 pe-0">
                                                <span className="period">
                                                    {DateTime.fromISO(record.start).toFormat("H:mm")}

                                                    {record.type === "break" && (
                                                        "—" + DateTime.fromISO(record.end).toFormat("H:mm")
                                                    )}

                                                    {record.type === "used" && (
                                                        "—" + DateTime.fromISO(record?.fullEnd ? record.fullEnd : record.end).toFormat("H:mm")
                                                    )}

                                                </span>
                                            </Col >
                                            <Col className="col-8">
                                                {record?.text}
                                            </Col >
                                        </Row>
                                    </div>
                                ))}
                            </div>
                        )}

                    </>
                ) : (
                    <Row className="vh-100 text-center">
                        <Col className="mt-5">
                            <Spinner animation="border" variant="secondary" />
                        </Col>
                    </Row>
                )}
            </Container >

            <ClientMainButton
                mainButtonText={recordDate ? "Записаться" : "Выберите дату и время"}
                disabled={!recordDate}
                handleMainButtonClick={handleSaveRecord}
            />

        </>
    )
};

export default ClientCalendar;
