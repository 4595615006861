import axios from "axios";

import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../App.js";
import { IconEditBg } from "../../components/Icons.js";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Container, Row, Col, Accordion, Spinner } from 'react-bootstrap';
import AdminPanel from "../../components/AdminPanel/AdminPanel.js";
import AdminMenu from "../../components/AdminMenu/AdminMenu.js";
import CatalogItem from "../../components/CatalogItem/CatalogItem.js";
import { NavLink, useNavigate } from "react-router-dom";
import "./Style.css";

const AdminCatalog = (props) => {

  //const Permissions = useContext(AppContext);
  const navigate = useNavigate();

  const [appData, setAppContext] = useContext(AppContext);

  //Кешировать каталог для визуального ускорения загрузки
  const catalogCash = true;
  const [catalog, setCatalog] = useState(catalogCash ? JSON.parse(localStorage.getItem(appData.guid + "_catalog")) : null);

  //данные для обновления после сортировки
  const [updateValues, setUpdateValues] = useState([]);


  useEffect(() => {

    const handleBackButton = () => {
      navigate(-1)
    }

    window?.Telegram.WebApp.BackButton.show();
    window?.Telegram.WebApp.BackButton.onClick(handleBackButton);

    return () => {
      window?.Telegram.WebApp.BackButton.hide();
      window?.Telegram.WebApp.BackButton.offClick(handleBackButton);
    };

  }, []);


  useEffect(() => {
    if (updateValues.length > 0) {
      axios.patch("/api/tenant_v1/catalog/", updateValues).then((result) => { });
    }
  }, [updateValues]);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios.get("/api/tenant_v1/catalog/").then((result) => {
      setCatalog(result.data);

      if (catalogCash) {
        localStorage.setItem(appData.guid + "_catalog", JSON.stringify(result.data));
      }

    });
  }, []);

  const getItemClass = (item, snapshot) => (
    (snapshot.isDragging ? "drag-item dragging" : "drag-item")
  );

  const handleItemClick = (guid) => {
    if (guid) {
      navigate("/app/master/tenant/catalog/item/" + guid + "/");
    }
  }

  function handleOnDragEnd(result) {

    const { source, destination } = result;

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    let sortArr = [];
    const items = Array.from(catalog.items);
    //const destinationParentGUID = destination.droppableId === "none" ? "" : destination.droppableId;
    const destinationParentGUID = destination.droppableId;


    if (source.droppableId === destination.droppableId) {
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

    } else {
      items.forEach(element => {
        if (element.guid === result.draggableId) {
          element.parent_guid = destinationParentGUID;
        }
      });

      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      sortArr.push({ "guid": result.draggableId, "parent_guid": destinationParentGUID });
    }


    //setCatalog({ "items": items, "parents": catalog.parents });
    setCatalog({ ...catalog, "items": items });

    items.forEach((item, index) => {
      if (item.guid !== catalog.items[index].guid) {
        sortArr.push({ "guid": item.guid, "sort": index });
      }
    })

    setUpdateValues(sortArr);

  }


  return (
    <>

      <AdminPanel />

      <AdminMenu title="Мои услуги" />

      <Container className="admin-catalog" >

        <Row className="mb-3">
          <Col>
            <NavLink to="/app/master/tenant/catalog/item/category/" className="btn btn-outline w-100">Добавить группу</NavLink>
          </Col>
          <Col>
            <NavLink to="/app/master/tenant/catalog/item/item/" className="btn btn-outline w-100">Добавить услугу</NavLink>
          </Col>
        </Row>

        {catalog ? (
          <>
            <Accordion defaultActiveKey={catalog.parents?.map((parent) => parent.guid)} activeKey={catalog.parents?.map((parent) => parent.guid)}>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                {catalog.parents?.map((parent) => (
                  <Accordion.Item key={parent.guid} eventKey={parent.guid} className={parent.active ? "" : "hide"}>

                    <Accordion.Header onClick={() => handleItemClick(parent.guid)}>
                      {parent.name}
                    </Accordion.Header>

                    <Accordion.Body>
                      <Droppable droppableId={parent.guid}>{/*parent.guid ? parent.guid : "none"*/}
                        {(provided) => (
                          <div className="catalog" {...provided.droppableProps} ref={provided.innerRef}>
                            {catalog.items?.map((item, index) => {
                              if (item.parent_guid === parent.guid) {
                                return (
                                  <Draggable key={item.guid} id={item.guid} draggableId={item.guid} index={index}>
                                    {(provided, snapshot) => (
                                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                        className={getItemClass(item, snapshot)}
                                      // onClick={() => handleItemClick(item.guid)}
                                      >
                                        <CatalogItem
                                          item={item}
                                          icon={<IconEditBg />}
                                          handleItemClick={() => handleItemClick(item.guid)}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              } else { return '' };
                            }
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>


                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </DragDropContext>
            </Accordion>

          </>
        )
          :
          (
            <Row className="vh-100 text-center">
              <Col className="mt-5">
                <Spinner animation="border" variant="secondary" />
              </Col>
            </Row>
          )
        }

      </Container >
    </>
  );
};

export default AdminCatalog;
