import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Spinner, Image, InputGroup } from 'react-bootstrap';
import AdminPanel from "../../components/AdminPanel/AdminPanel.js";
import AdminMenu from "../../components/AdminMenu/AdminMenu.js";
import DialigConfirm from "../../components/DialigConfirm/DialigConfirm.js";
import { IconAddBigPhoto } from "../../components/Icons.js";
import { useForm } from "react-hook-form";
import "./Style.css";

const AutomaticMailingItem = (props) => {

  const { guid } = useParams();
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [item, setItem] = useState();
  const [submitVisible, setSubmitVisible] = useState(false);

  useEffect(() => {

    const handleBack = () => {
      navigate(-1);
    };

    window?.Telegram.WebApp.BackButton.show();
    window?.Telegram.WebApp.BackButton.onClick(handleBack);

    return () => {
      window?.Telegram.WebApp.BackButton.hide();
      window?.Telegram.WebApp.BackButton.offClick(handleBack);
    };

  }, [submitVisible]);

  const handleChange = () => {
    setSubmitVisible(true);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (guid) {
      axios.get("/api/tenant_v1/automatic_mailings/" + guid + "/").then((result) => {
        setItem(result?.data);

      });
    }
  }, [guid]);

  const onSave = (formValues) => {
    setSubmitVisible(false);
    axios.patch("/api/tenant_v1/automatic_mailings/" + guid + "/", formValues).then((result) => {
      navigate(-1);
    });
  };

  return (
    <>

      <AdminPanel />
      <AdminMenu title="Автоматическая рассылка" />
      <Container className="admin-catalog-item">

        {item ? (

          <Form onChange={handleChange}>

            <h1 className="mt-3 mb-4">{item?.name}</h1>

            <Form.Group className="mb-4" >
              <Form.Check // prettier-ignore
                type="switch"
                id="active"
                label="Активировать рассылку"
                inline
                defaultChecked={item?.active}
                // name="active"
                {...register("active")}
              />
            </Form.Group>

            {/* <Form.Group className={"mb-4" + (item?.period_name ? "" : " d-none")} >
              <Form.Label>{item?.period_name}</Form.Label>
              <InputGroup>
                <Form.Control
                  readOnly={true}
                  defaultValue={Math.abs(item?.period_value / item?.period_size?.value)}
                />
                <InputGroup.Text >{item?.period_size?.name} </InputGroup.Text>
              </InputGroup>
            </Form.Group> */}

            <Form.Group className={"mb-4" + (item?.period_name ? "" : " d-none")} >
              <Form.Label>{item?.period_name}</Form.Label>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Текст сообщения</Form.Label>
              <Form.Control as="textarea" rows={5} readOnly={true} defaultValue={item?.template} />
            </Form.Group>
            {item?.description !== "" && (
              <Form.Group className="mb-4">
                <Form.Label>Как работает рассылка</Form.Label>
                <Row className="text-break">
                  <Col style={{ "white-space": "pre-wrap" }}>
                    {item?.description}
                  </Col>
                </Row >
                {/* <Form.Control as="textarea" rows={5} readOnly={true} defaultValue={item?.description} /> */}
              </Form.Group>
            )}


            <DialigConfirm
              show={submitVisible}
              handleYes={handleSubmit(onSave)}
              handleCancel={() => navigate(-1)}
            />

          </Form>
        ) : (
          <Row className="vh-100 text-center">
            <Col className="mt-5">
              <Spinner animation="border" variant="secondary" />
            </Col>
          </Row >
        )}


      </Container >
    </>
  );
};

export default AutomaticMailingItem;
