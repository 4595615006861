import { Image } from 'react-bootstrap';
import { timeFormat } from "../../components/tools.js";
import { IconNoPhoto, IconHourglass, IconMoney } from "../../components/Icons.js";
import "./Style.css";

const CatalogItem = (props) => {

    const { item, icon, className, handleItemClick } = props;

    const getItemClass = (item) => {
        let qwe = "item";

        if (item?.active === false) {
            qwe += " hide";
        }

        if (className) {
            qwe += " " + className;
        }

        return qwe;
    }



    return (


        <div key={item.guid} className={getItemClass(item)} onClick={() => handleItemClick(item.guid)} >
            <div className="img">
                <div className="icons">{icon}</div>

                {item?.image ?
                    <Image src={"/api/client_v1/image/" + item?.image} rounded fluid />
                    :
                    <IconNoPhoto />
                }
            </div>

            <div className="content">
                <div className="title">{item.name}</div>
                <div className="description">

                    <span className="me-3"><IconHourglass /> {timeFormat(item.duration)}</span>
                    <span className="me-3"><IconMoney /> {item.price.toLocaleString('ru-RU')} ₽</span>

                    {/* <NavLink>подробнее</NavLink> */}
                </div>

            </div>
        </div>

    );
};

export default CatalogItem;
