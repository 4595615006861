import { useContext } from "react";
import { Row, Col, Accordion } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { IconMarker, IconWallet } from "../../components/Icons.js";

import "./Style.css";
import { AppContext } from "../../App.js";

const ClientMenu = (props) => {
    const [appData, setAppContext] = useContext(AppContext);

    return (
        <>
            <Row className="mb-3 client-menu-balance">
                <Col className="col ps-3 text-start">
                    <IconWallet /> Мой баланс
                </Col>
                <Col className="col text-end">
                    {appData?.client_balance.toLocaleString('ru-RU')} ₽
                </Col>
            </Row>
            <Row className="mb-2">
                <Col className="col-6 pe-0">
                    <a href={"https://t.me/" + appData.master_contact + "?text=Здравствуйте, "} target="_blank" rel="noopener noreferrer" className="btn btn-base w-100">
                        Написать мастеру
                    </a>
                </Col>
                <Col className="col-6">
                    <Link className={"btn btn-base w-100"} to={"/app/master/client/records/"} onClick={() => setAppContext({ ...appData, "basket": {} })
                    }>
                        Мои записи
                        {appData?.records_actived_count > 0 && (<span className={"records-count"}>{appData.records_actived_count}</span>)}

                    </Link>
                </Col>
            </Row>

            <Accordion defaultActiveKey={[]} className="address mb-2">
                <Accordion.Item>

                    <Accordion.Header >
                        <IconMarker /> <span className="address-header" >Адрес мастера</span>
                    </Accordion.Header>

                    <Accordion.Body className="text-center">
                        {appData.master_address}
                    </Accordion.Body>

                </Accordion.Item>
            </Accordion>
        </>
    );
};

export default ClientMenu;
