import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { Tab, Spinner } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { useNavigate } from "react-router-dom";

import { Container, Row, Col, Form } from 'react-bootstrap';


import AdminPanel from "../../components/AdminPanel/AdminPanel";
import AdminMenu from "../../components/AdminMenu/AdminMenu";

import { v4 as uuidv4 } from "uuid";

const AdminTariff = (props) => {

  //const Permissions = useContext(AppContext);

  const [tariff, setTariff] = useState();
  // const [formValue, setformValue] = useState({});
  // const [submitEnabled, setSubmitEnabled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleBackButton = () => {
      navigate(-1);
    }

    window?.Telegram.WebApp.BackButton.show();
    window?.Telegram.WebApp.BackButton.onClick(handleBackButton);

    return () => {
      window?.Telegram.WebApp.BackButton.hide();
      window?.Telegram.WebApp.BackButton.offClick(handleBackButton);
    };

  }, []);

  useEffect(() => {
    axios.get("/api/tenant_v1/tariff/").then((result) => {
      setTariff(result.data);
      // setSubmitEnabled(true);
    });
  }, []);


  function ChangeTariff(guid) {
    // e.preventDefault();
    // setSubmitEnabled(false);
    //alert("1111111111111");
    axios.patch("/api/tenant_v1/tariff/", { "guid": guid }).then((result) => {
      setTariff(result.data);
      window.scrollTo(0, 0);

      //   setSubmitEnabled(true);
      //setTariff(result.data);
    });
  }

  return (
    <>

      <AdminPanel />

      <AdminMenu title="Тарифы" />

      <Container >

        {tariff ? (
          <Tabs
            defaultActiveKey={tariff.guid}
            id="tab-tariff"
            className="mb-3"
            justify
          >

            {tariff.list.map((item) => (
              <Tab key={item.guid} eventKey={item.guid} title={item.name} className="text-center">
                <div className="mb-3">{item.description}</div>
                <div className="mb-3">{item.price} рублей в месяц</div>

                {item.guid !== tariff.guid ?
                  (<Button className="mb-3" variant="success" onClick={() => ChangeTariff(item.guid)}>Активировать</Button>)
                  :
                  (<Alert variant="success">Ваш тариф</Alert>)
                }
              </Tab>
            ))}

          </Tabs>
        ) : (


          <Row className="vh-100 text-center">
            <Col className="mt-5">
              <Spinner animation="border" variant="secondary" />
            </Col>
          </Row>
        )}

      </Container>
    </>
  );
};

export default AdminTariff;
