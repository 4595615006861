// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-mailings .mailing-item-on,
.admin-mailings .mailing-item-off {
  margin: 0px 0px 0.5rem 0px;
  min-height: 3rem;
  line-height: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.admin-mailings .mailing-item-on {
}

.admin-mailings .mailing-item-off {
  background-color: var(--theme-calendar-record);
  border-color: var(--theme-calendar-record);
  color: var(--theme-disabled-text);
}

.admin-catalog-item {
  margin-bottom: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/appMaster/AdminAutomaticMailings/Style.css"],"names":[],"mappings":"AAAA;;EAEE,0BAA0B;EAC1B,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,sBAAsB;;EAEtB,2DAA2D;EAC3D,sCAAsC;AACxC;;AAEA;AACA;;AAEA;EACE,8CAA8C;EAC9C,0CAA0C;EAC1C,iCAAiC;AACnC;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".admin-mailings .mailing-item-on,\n.admin-mailings .mailing-item-off {\n  margin: 0px 0px 0.5rem 0px;\n  min-height: 3rem;\n  line-height: 1rem;\n  padding-top: 0.5rem;\n  padding-bottom: 0.5rem;\n\n  border: var(--bs-border-width) solid var(--bs-border-color);\n  border-radius: var(--bs-border-radius);\n}\n\n.admin-mailings .mailing-item-on {\n}\n\n.admin-mailings .mailing-item-off {\n  background-color: var(--theme-calendar-record);\n  border-color: var(--theme-calendar-record);\n  color: var(--theme-disabled-text);\n}\n\n.admin-catalog-item {\n  margin-bottom: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
