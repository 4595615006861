import axios from "axios";

import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../App.js";
import { IconEditBg } from "../../components/Icons.js";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Container, Row, Col, Accordion, Spinner, Card } from 'react-bootstrap';
import AdminPanel from "../../components/AdminPanel/AdminPanel.js";
import AdminMenu from "../../components/AdminMenu/AdminMenu.js";
import CatalogItem from "../../components/CatalogItem/CatalogItem.js";
import { NavLink, useNavigate } from "react-router-dom";
import { IconBigArrowRight } from "../../components/Icons.js";



import "./Style.css";

const AutomaticMailingsCatalog = (props) => {

  const navigate = useNavigate();
  const [appData, setAppContext] = useContext(AppContext);
  const [catalog, setCatalog] = useState();

  useEffect(() => {

    const handleBackButton = () => {
      navigate(-1)
    }

    window?.Telegram.WebApp.BackButton.show();
    window?.Telegram.WebApp.BackButton.onClick(handleBackButton);

    return () => {
      window?.Telegram.WebApp.BackButton.hide();
      window?.Telegram.WebApp.BackButton.offClick(handleBackButton);
    };

  }, []);

  useEffect(() => {
    axios.get("/api/tenant_v1/automatic_mailings/").then((result) => {
      setCatalog(result.data);
    });
  }, []);

  const handleItemClick = (guid) => {
    if (guid) {
      navigate("/app/master/tenant/automatic_mailings/" + guid + "/");
    }
  }

  return (
    <>

      <AdminPanel />
      <AdminMenu title="Автоматические рассылки" />
      <Container className="admin-mailings mt-2" >

        {catalog ? (
          <Row>
            <Col >
              {catalog?.map((item, index) => (
                <Row key={index} className={"align-items-center mailing-item-" + (item.active ? "on" : "off")} onClick={() => handleItemClick(item.guid)} >
                  <Col xs={10}>
                    {item.name}
                  </Col>
                  <Col xs={2}>
                    <IconBigArrowRight />
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        )
          :
          (
            <Row className="vh-100 text-center">
              <Col className="mt-5">
                <Spinner animation="border" variant="secondary" />
              </Col>
            </Row>
          )
        }

      </Container >
    </>
  );
};

export default AutomaticMailingsCatalog;
