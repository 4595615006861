// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catalog .drag-item {
  padding-bottom: 1rem;
}

.catalog .item {
  word-break: break-word;
  background-color: white;
  box-shadow: 0px 0px 0px 0px gray;
  opacity: 1;
  border-radius: var(--bs-border-radius) !important;
  display: flex;
  width: 100%;
  border-radius: 0px;
  -webkit-user-select: none;
          user-select: none;
}

.catalog .item.hide {
  filter: grayscale(75%);
  opacity: 0.85;
}

.catalog .dragging .item {
  box-shadow: -5px 0px 5px 5px gray;
  opacity: 0.75;
}

.catalog .item .img {
  width: 70px;
  flex: none;
  margin-right: 10px;
}

.catalog .item .img .icons {
  position: relative;
  height: 0px;
  left: -4px;
  top: -8px;
}

/* .catalog .catalog-item .img .check {
  top: -7px;
  left: -3px;
  position: relative;
  height: 0px;
}

.catalog .catalog-item .img .check input {
  opacity: 0;
}

.catalog .catalog-item .img .check input:checked {
  opacity: 1;
} */

.catalog .item .content .title {
  font-size: 0.875rem;
  font-weight: 400;
}

.catalog .item .content .description {
  font-size: 0.75rem;
  font-weight: 300;
}

/* --- карточки каталога --- */
`, "",{"version":3,"sources":["webpack://./src/components/CatalogItem/Style.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,gCAAgC;EAChC,UAAU;EACV,iDAAiD;EACjD,aAAa;EACb,WAAW;EACX,kBAAkB;EAClB,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,iCAAiC;EACjC,aAAa;AACf;;AAEA;EACE,WAAW;EACX,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,UAAU;EACV,SAAS;AACX;;AAEA;;;;;;;;;;;;;GAaG;;AAEH;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA,8BAA8B","sourcesContent":[".catalog .drag-item {\n  padding-bottom: 1rem;\n}\n\n.catalog .item {\n  word-break: break-word;\n  background-color: white;\n  box-shadow: 0px 0px 0px 0px gray;\n  opacity: 1;\n  border-radius: var(--bs-border-radius) !important;\n  display: flex;\n  width: 100%;\n  border-radius: 0px;\n  user-select: none;\n}\n\n.catalog .item.hide {\n  filter: grayscale(75%);\n  opacity: 0.85;\n}\n\n.catalog .dragging .item {\n  box-shadow: -5px 0px 5px 5px gray;\n  opacity: 0.75;\n}\n\n.catalog .item .img {\n  width: 70px;\n  flex: none;\n  margin-right: 10px;\n}\n\n.catalog .item .img .icons {\n  position: relative;\n  height: 0px;\n  left: -4px;\n  top: -8px;\n}\n\n/* .catalog .catalog-item .img .check {\n  top: -7px;\n  left: -3px;\n  position: relative;\n  height: 0px;\n}\n\n.catalog .catalog-item .img .check input {\n  opacity: 0;\n}\n\n.catalog .catalog-item .img .check input:checked {\n  opacity: 1;\n} */\n\n.catalog .item .content .title {\n  font-size: 0.875rem;\n  font-weight: 400;\n}\n\n.catalog .item .content .description {\n  font-size: 0.75rem;\n  font-weight: 300;\n}\n\n/* --- карточки каталога --- */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
