export function declOfNum(number, titles, full = false) {
    const cases = [2, 0, 1, 1, 1, 2]

    const result = titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
    return full ? `${number} ${result}` : result
}


export function timeFormat(minutes) {
    let h = Math.floor(minutes / 60);
    let m = minutes % 60

    return (h > 0 ? h + " ч " : "") + (m > 0 ? m + declOfNum(m, [" минута", " минуты", " минут"]) : "");
}


