import { useEffect, useState } from "react";

import { Row, Col, Button } from 'react-bootstrap';

import { IconArrowRight, IconArrowLeft } from "../Icons";

import './Style.css';

import { DateTime } from 'luxon'

const CalendarHeader = (props) => {

    const [calendarDate, setCalendarDate] = useState();
    const [calendarHeader, setCalendarHeader] = useState();

    useEffect(() => {

        setCalendarDate(props.calendarDate);

    }, [props.calendarDate]);

    useEffect(() => {
        if (calendarDate) {
            props.handleUpdateCalendarDate(calendarDate);
            const dayArray = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
            let result = {};
            let today = DateTime.now().startOf('day').valueOf();

            result.date = calendarDate;
            result.prev_week = calendarDate.plus({ days: - 7 });
            result.next_week = calendarDate.plus({ days: + 7 });

            result.week = [];
            const currentWeekDay = calendarDate.weekday;
            for (let d = 1; d < 8; d++) {
                let weekDay = calendarDate.plus({ days: d - currentWeekDay });

                let classNames = [weekDay.valueOf() === calendarDate.valueOf() ? "select" : ""];

                classNames.push(weekDay.startOf('day').valueOf() === today ? "today" : "");

                result.week[d] = {
                    name: dayArray[d],
                    number: weekDay.toFormat("d"),
                    date: weekDay,
                    className: classNames.join(" ")
                }
            }
            setCalendarHeader(result);
        }
    }, [calendarDate]);

    const handleSetCalendarDate = (date) => {
        if (props?.calendarMinDate) {
            date = date > props?.calendarMinDate ? date : props?.calendarMinDate;
        }
        setCalendarDate(date);
    }

    return (
        <div className="calendar-header">

            <Row className="mb-2">
                <Col className="text-center">
                    <Row>
                        <Col className="text-center mb-2"> {calendarHeader?.date.setLocale("ru").toFormat("d MMMM yyyy г.")}</Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <Button
                                onClick={() => handleSetCalendarDate(calendarHeader?.prev_week)}
                                variant={"outline"}
                                className="week me-2"
                                disabled={props?.calendarMinDate?.startOf('week') > calendarHeader?.prev_week}>
                                <IconArrowLeft />
                            </Button>
                            <Button onClick={() => handleSetCalendarDate(calendarHeader?.next_week)} variant={"outline"} className="week"><IconArrowRight /></Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="mb-2">
                <Col >
                    <div className="calendar-header-week">
                        {calendarHeader?.week.map((item, index) => (
                            <Button
                                key={index}
                                onClick={() => setCalendarDate(item.date)}
                                variant={"calendar-day"}
                                className={item.className}
                                disabled={props?.calendarMinDate > item.date.endOf('day')}
                            >
                                <span className="name">{item.name}</span><span className="number">{item.number}</span>
                            </Button>
                        ))}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default CalendarHeader;
