// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-clients .find {
  position: relative;
  padding: 10px 10px 14px 10px;
  background-color: var(--theme-header-color);
  border-radius: 0.5rem;
}

.admin-clients .find .icon {
  position: absolute;
  right: 8px;
  bottom: 10px;
  width: 2rem;
  height: 2.25rem;
  text-align: center;
}

.admin-clients .item {
  border: solid 1px var(--theme-header-color);
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  -webkit-user-select: none;
          user-select: none;
}

.admin-clients .item:active {
  background-color: var(--theme-header-color);
}

.admin-client-detail .client-phone {
  padding: 0.55rem 0.75rem;
}
`, "",{"version":3,"sources":["webpack://./src/appMaster/AdminClients/Style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,4BAA4B;EAC5B,2CAA2C;EAC3C,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,WAAW;EACX,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,2CAA2C;EAC3C,qBAAqB;EACrB,qBAAqB;EACrB,mBAAmB;EACnB,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".admin-clients .find {\n  position: relative;\n  padding: 10px 10px 14px 10px;\n  background-color: var(--theme-header-color);\n  border-radius: 0.5rem;\n}\n\n.admin-clients .find .icon {\n  position: absolute;\n  right: 8px;\n  bottom: 10px;\n  width: 2rem;\n  height: 2.25rem;\n  text-align: center;\n}\n\n.admin-clients .item {\n  border: solid 1px var(--theme-header-color);\n  border-radius: 0.5rem;\n  margin-bottom: 0.5rem;\n  font-size: 0.875rem;\n  user-select: none;\n}\n\n.admin-clients .item:active {\n  background-color: var(--theme-header-color);\n}\n\n.admin-client-detail .client-phone {\n  padding: 0.55rem 0.75rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
