import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Spinner, Form } from 'react-bootstrap';
import AdminPanel from "../../components/AdminPanel/AdminPanel.js";
import { DateTime } from 'luxon'
import { timeFormat } from "../../components/tools.js";
import { useNavigate } from "react-router-dom";
import { IconCalendar, IconHourglass, IconMoney, IconClient, IconСall, IconСhat, IconClock, IconWallet, IconDelete } from "../../components/Icons.js";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import DialigConfirm from "../../components/DialigConfirm/DialigConfirm.js";
import { GetTemplate } from "../../components/DialogTemplates/GetTemplate.js";
import AdminMenu from "../../components/AdminMenu/AdminMenu.js";
import { AppContext } from "../../App.js";

import "./Style.css";

const AdminClientRecord = () => {

    const { guid } = useParams();
    const [appData, setAppContext] = useContext(AppContext);
    const navigate = useNavigate();

    const [submitVisible, setSubmitVisible] = useState(false);
    const [recordData, setRecordData] = useState();
    const [clientPay, setClientPay] = useState();
    const [basketPrice, setBasketPrice] = useState();

    const { register, handleSubmit, setValue, getValues } = useForm();


    useEffect(() => {
        const handleBackButton = () => {
            //navigate(-1);
            navigate("/app/master/tenant/calendar/" + recordData?.start + "/");
        }

        window?.Telegram.WebApp.BackButton.show();
        window?.Telegram.WebApp.BackButton.onClick(handleBackButton);

        return () => {
            window?.Telegram.WebApp.BackButton.hide();
            window?.Telegram.WebApp.BackButton.offClick(handleBackButton);
        };

    }, [recordData?.start]);



    useEffect(() => {

        window.scrollTo(0, 0);

        const controller = new AbortController();

        axios.get("/api/tenant_v1/calendar/record/" + guid + "/", {
            signal: controller.signal
        }).then((result) => {
            //Считаем итог по документу
            result.data["basket_sum"] = result.data.basket.reduce((sum, item) => sum + item.price, 0);
            setRecordData(result.data);
            setBasketPrice(result.data?.basket.reduce((acc, val) => (acc + Number(val.price)), 0));
            setClientPay(result.data.status === "completed");
        });

        return () => {
            controller.abort();
        };

    }, []);


    const handleDelete = () => {
        GetTemplate((template) => {
            window.Telegram.WebApp.showConfirm(template, (result) => {
                if (result) {
                    axios.patch("/api/client_v1/records/",
                        { guid: guid, status: "canceled" }
                    ).then((result) => {
                        //setAppContext({ ...appData, records_actived_count: result.data.records_actived_count });
                        //navigate(-1);
                        navigate("/app/master/tenant/calendar/" + recordData?.start + "/");
                    });
                }
            });
        }, "DialogCancelingRecordClient");
    }


    const onSave = (data) => {
        axios.patch("/api/tenant_v1/calendar/record/" + guid + "/", data
        ).then((result) => {
            setSubmitVisible(false);
            // handleUpdateCalendarDate(DateTime.fromISO(calendarDate.toISO()));
        });
    }

    const onFormChange = (e) => {
        let formValues = getValues();
        setSubmitVisible(true);

        let basketPrice = formValues?.basket.reduce((acc, val) => (acc + Number(val.price)), 0);

        //Если сумма вообще без значения
        if (formValues.client_pay_sum === "") {
            setValue("client_pay_sum", 0);
        }

        //Если не оплачено и нет суммы, то ставим сумму заказа
        if (e.target.name === "status" && formValues.status === "completed" && !formValues.client_pay_sum) {
            setValue("client_pay_sum", basketPrice);
        }

        if (formValues.status !== "completed") {
            setValue("client_pay_sum", 0);
        }

        setBasketPrice(basketPrice);
        setClientPay(formValues.status === "completed");
    }

    return (
        <>

            <AdminPanel />

            <AdminMenu title="Запись клиента" />

            <Container className="admin admin-record-detail">

                {recordData ? (
                    <>
                        <Row>

                            <Col className="col-12 record-name mb-3"><IconClient /> {recordData.client_name}</Col>

                            <Col className="col-8 record-name text-start">
                                <IconWallet className="me-1" />Баланс клиента
                            </Col>
                            <Col className="col-4  record-name text-end">
                                {recordData.client_balance} ₽
                            </Col>

                            <Col className="col-12 mt-3 mb-4 d-flex">

                                <Form.Group className="record-phone flex-fill" >
                                    <Form.Control
                                        type="text"
                                        disabled={true}
                                        defaultValue={recordData.telegram_phone ? recordData.telegram_phone : "Клиент не поделился номером"}
                                        className="client-phone"
                                    />
                                </Form.Group>

                                <a href={"tel:" + recordData.telegram_phone} className={"btn btn-base btn-box ms-1" + (recordData.telegram_phone ? "" : " disabled")} target="_blank" rel="nofollow noreferrer noopener">
                                    <IconСall />
                                </a>

                                <a href={"https://t.me/" + recordData.telegram_phone + "?text=Здравствуйте, "} className={"btn btn-base btn-box ms-1" + (recordData.telegram_phone ? "" : " disabled")} target="_blank" rel="nofollow noreferrer noopener">
                                    <IconСhat />
                                </a>

                            </Col>
                            <Col className="col-2 ">

                            </Col>

                            <Col className="col-8 mb-2 record-name">
                                <IconCalendar className="me-1" />{DateTime.fromISO(recordData.start).setLocale("ru").toFormat("d MMMM, EEEE")}
                                <br />
                                <IconClock className="ms-2 me-1" />{DateTime.fromISO(recordData.start).toFormat("H:mm")}
                                &nbsp;-&nbsp;
                                {DateTime.fromISO(recordData.end).toFormat("H:mm")}
                            </Col>
                            <Col className="col-2 pt-2" onClick={handleDelete} >
                                <IconDelete />
                            </Col>

                        </Row >


                        <Form onChange={onFormChange} className="mb-5">
                            <div className="records-box">
                                {recordData.basket.map((item, index) => (
                                    <Row className="mb-3" key={index}>
                                        <Col className="col-12">
                                            {item.name}
                                        </Col>
                                        <Col className="col-5 pt-2 pe-0">
                                            <IconHourglass /> {timeFormat(item.duration)}
                                        </Col>
                                        <Col className="col-6 text-end ps-0">
                                            <IconMoney />
                                            <input type="hidden"  {...register("basket[" + index + "].id", { value: item.id })} />
                                            <Form.Control
                                                type="number"
                                                min="0"
                                                className="w-50 d-inline ms-1 me-1 text-end"
                                                {...register("basket[" + index + "].price", { min: 0, value: item.price, required: true })}
                                            />
                                            ₽
                                        </Col>
                                    </Row >
                                ))}

                                <Row>

                                    <Col className="col-5 fw-bold">
                                        Итого:
                                    </Col>

                                    <Col className="col-6 text-end fw-bold">
                                        <span className="pe-3">{basketPrice}</span>₽
                                    </Col>

                                </Row >
                            </div>

                            <Row className="mt-3 mb-2">

                                <Col className="col-5 pt-2"  >
                                    <Form.Label htmlFor="recordStatus">Статус записи</Form.Label>
                                </Col>

                                <Col className="col-7"  >
                                    <Form.Select
                                        name="status"
                                        id="recordStatus"
                                        //onClick={handleClientPayClick}
                                        {...register("status", { value: recordData?.status, required: true })}
                                    >
                                        <option value="created">Ожидание клиента</option>
                                        <option value="confirmed">Клиент подтвердил приход</option>
                                        <option value="completed" className="fw-bold">Клиент пришел</option>
                                        <option value="canceled" hidden>Запись отменена</option>
                                        <option value="skipped">Клиент не пришел</option>
                                    </Form.Select>
                                </Col>
                            </Row>

                            <Row className="mb-5">
                                <Col className="col-5 pt-2"  >
                                    <Form.Label htmlFor="recordClientPay">Оплачено, ₽</Form.Label>
                                </Col>

                                <Col className="col-7 text-end">
                                    <Form.Group >
                                        <Form.Control
                                            id="recordClientPay"
                                            type="number"
                                            disabled={!clientPay}
                                            className="text-end"
                                            {...register("client_pay_sum", { min: 0, value: recordData.pay_sum })}
                                        />
                                    </Form.Group>

                                </Col>

                            </Row >
                            <DialigConfirm
                                show={submitVisible}
                                handleYes={handleSubmit(onSave)}
                                //handleCancel={props.handleCancel}
                                handleCancel={
                                    () => navigate("/api/tenant_v1/calendar/record/" + guid + "/")
                                    //    () => setSubmitVisible(false)
                                }
                            />
                        </Form >
                    </>

                ) : (
                    <Row className="vh-100 text-center">
                        <Col className="mt-5">
                            <Spinner animation="border" variant="secondary" />
                        </Col>
                    </Row >
                )}

            </Container >

        </>
    );
};
export default AdminClientRecord;
