import { GetTemplate } from "./GetTemplate.js";

export const PopUpAlert = (code, ...param) => {

    GetTemplate((template) =>
        window?.Telegram?.WebApp.showAlert(template),
        code,
        ...param
    );

}