import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../App.js";
import { Row, Col, Accordion, Spinner, Button } from 'react-bootstrap';
import ClientRecordItem from "./ClientRecordItem.js"
import { GetTemplate } from "../../components/DialogTemplates/GetTemplate.js";

const ClientRecordsAccordion = (props) => {
    let { client_guid, restoreRecord, openAccordion } = props;

    const [clientRecords, setClientRecords] = useState()
    const [archivedRecords, setArchivedRecords] = useState()
    const [archivedOffset, setArchivedOffset] = useState(0)

    const [appData, setAppContext] = useContext(AppContext);
    const [accordion, setAccordion] = useState(openAccordion);

    useEffect(() => {

        if (accordion === "actived") {

            setArchivedOffset(0);
            setArchivedRecords();

            let params = client_guid ? "?guid=" + client_guid : "";

            const controller = new AbortController();
            axios.get("/api/client_v1/records/" + params, {
                signal: controller.signal
            }).then((result) => {
                setClientRecords(result?.data);
            });

            return () => {
                controller.abort();
            };
        }

    }, [accordion]);

    useEffect(() => {

        if ((accordion === "archived") && (archivedOffset !== undefined)) {
            const controller = new AbortController();
            let params = "?archived=" + archivedOffset + (client_guid ? "&guid=" + client_guid : "");
            axios.get("/api/client_v1/records/" + params, {
                signal: controller.signal
            }).then((result) => {
                setArchivedRecords([...(archivedRecords ? archivedRecords : []), ...result.data]);
                if (result.data.length === 0) {
                    setArchivedOffset(undefined);
                }
            });

            return () => {
                controller.abort();
            };
        }
    }, [accordion, archivedOffset]);

    useEffect(() => {
        if (!client_guid) {
            setAppContext({ ...appData, records_actived_count: clientRecords?.length });
        }
    }, [clientRecords]);

    const handleCancelRecord = (item) => {
        if (item.guid) {
            GetTemplate((template) => {
                window.Telegram.WebApp.showConfirm(template, (result) => {
                    if (result) {
                        axios.patch("/api/client_v1/records/", { guid: item.guid, status: "canceled" }
                        ).then((result) => {
                            setClientRecords(clientRecords.filter((el) => el.guid !== item.guid));
                        });
                    }
                });
            }, "DialogCancelingRecordClient");
        }
    }

    const handleRestoreRecord = (item) => {
        if (item.guid) {
            GetTemplate((template) => {
                window.Telegram.WebApp.showConfirm(template, (result) => {
                    if (result) {
                        axios.patch("/api/client_v1/records/", { guid: item.guid, status: "created" }
                        ).then((result) => {
                            if (result?.data?.error) {
                                window.Telegram.WebApp.showAlert(result?.data?.error);
                            } else {
                                setArchivedRecords(archivedRecords.filter((el) => el.guid !== item.guid));
                            }
                        });
                    }
                });
            }, "DialogRestoreRecordClient");
        }
    }

    return (

        <Accordion defaultActiveKey={openAccordion} onSelect={setAccordion}>

            <Accordion.Item eventKey="actived"  >
                <Accordion.Header>Активные</Accordion.Header>
                <Accordion.Body >
                    {clientRecords ? (
                        <div className="records actived">
                            {clientRecords.length === 0 && (
                                <h1 className="mt-4 mb-4">Нет актуальных записей</h1>
                            )}

                            {clientRecords?.map((item, index) => (
                                <ClientRecordItem key={index} item={item} cancelRecord={true} handleCancelRecord={handleCancelRecord} />
                            ))}
                        </div>
                    ) : (
                        <Row className="mt-5 mb-5 text-center">
                            <Col>
                                <Spinner animation="border" variant="secondary" />
                            </Col>
                        </Row >
                    )}
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="archived" >
                <Accordion.Header>Архив</Accordion.Header>
                <Accordion.Body className="records archived">
                    {archivedRecords ? (
                        <div className="records archived">
                            {archivedRecords?.length === 0 && (
                                <h1 className="mt-4 mb-4">Нет архивных записей</h1>
                            )}

                            {archivedRecords?.map((item, index) => (
                                <ClientRecordItem key={index} item={item} restoreRecord={restoreRecord} handleRestoreRecord={handleRestoreRecord} />
                            ))}
                        </div>
                    ) : (
                        <Row className="mt-5 mb-5 text-center">
                            <Col >
                                <Spinner animation="border" variant="secondary" />
                            </Col>
                        </Row >
                    )}
                    {archivedOffset !== undefined && (
                        <Button variant="outline" className="w-100" onClick={() => setArchivedOffset(archivedRecords.length)}>Показать еще</Button>
                    )}

                </Accordion.Body>
            </Accordion.Item>
        </Accordion>

    );
};

export default ClientRecordsAccordion;