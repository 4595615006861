import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Accordion, Spinner, Button } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import { MyZamLogo } from "../../components/Icons.js";

import "./Style.css";


import { Zoom, Navigation, Pagination } from 'swiper/modules';

const Doc = (props) => {

  const params = useParams();

  const navigate = useNavigate();

  const [doc, setDoc] = useState();

  useEffect(() => {
    const handleBackButton = () => {
      navigate(-1);
    }

    window?.Telegram.WebApp.BackButton.show();
    window?.Telegram.WebApp.BackButton.onClick(handleBackButton);

    return () => {
      window?.Telegram.WebApp.BackButton.hide();
      window?.Telegram.WebApp.BackButton.offClick(handleBackButton);
    };

  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios.get("/api/public_v1/doc/" + params["id"]).then((result) => {
      setDoc(result.data);
    });
  }, []);


  return (
    <Container className="container-fluid landing" >
      {doc ? (
        <Row >
          <Col className="mt-4">
            <MyZamLogo className="d-block scale-7" />
            <div className="mt-4" dangerouslySetInnerHTML={{ __html: doc }}></div>
            <Button className="mt-4" onClick={() => navigate(-1)}>Назад</Button>
          </Col>
        </Row>
      ) : (
        <Row className="vh-100 text-center ">
          <Col className="mt-5">
            <MyZamLogo className="d-block mb-5" />
            <Spinner animation="border" variant="secondary" />
          </Col>
        </Row>
      )}
    </Container >
  );
};

export default Doc;
