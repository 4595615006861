// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-button .perimeter {
  border: solid 1px var(--theme-icon-color);
  padding: 2px 4px 2px 4px;
  margin: 0px 2px 0px 2px;
  border-radius: 10px;
  display: inline-block;
}

.main-button {
  background: var(--theme-header-color);
  border-radius: 10px 10px 0px 0px;
  font-family: "Jost" !important;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 8px;
  transform-origin: 0% 100%;
  transition: all 0.25s;
}

.main-button .header {
  color: black;
  padding-top: 12px;
  padding-bottom: 6px;
  width: 100%;
  line-height: 0.875rem;
  font-size: 0.875rem;
  font-weight: 400;
}

.main-button .header SVG {
  display: inline-block;
  position: relative;
  top: -1px;
  margin: 0px 0px 0px 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ClientMainButton/Style.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,wBAAwB;EACxB,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,qCAAqC;EACrC,gCAAgC;EAChC,8BAA8B;EAC9B,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,WAAW;EACX,qBAAqB;EACrB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,SAAS;EACT,wBAAwB;AAC1B","sourcesContent":[".main-button .perimeter {\n  border: solid 1px var(--theme-icon-color);\n  padding: 2px 4px 2px 4px;\n  margin: 0px 2px 0px 2px;\n  border-radius: 10px;\n  display: inline-block;\n}\n\n.main-button {\n  background: var(--theme-header-color);\n  border-radius: 10px 10px 0px 0px;\n  font-family: \"Jost\" !important;\n  padding-left: 12px;\n  padding-right: 12px;\n  padding-bottom: 8px;\n  transform-origin: 0% 100%;\n  transition: all 0.25s;\n}\n\n.main-button .header {\n  color: black;\n  padding-top: 12px;\n  padding-bottom: 6px;\n  width: 100%;\n  line-height: 0.875rem;\n  font-size: 0.875rem;\n  font-weight: 400;\n}\n\n.main-button .header SVG {\n  display: inline-block;\n  position: relative;\n  top: -1px;\n  margin: 0px 0px 0px 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
