import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { GetTemplate } from "../../components/DialogTemplates/GetTemplate.js";
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import AdminPanel from "../../components/AdminPanel/AdminPanel.js";
import AdminMenu from "../../components/AdminMenu/AdminMenu.js";
import { useNavigate } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";

const AdminBotButton = (props) => {

  //const Permissions = useContext(AppContext);
  const navigate = useNavigate();


  useEffect(() => {
    const handleBackButton = () => {
      navigate(-1);
    }

    window?.Telegram.WebApp.BackButton.show();
    window?.Telegram.WebApp.BackButton.onClick(handleBackButton);

    return () => {
      window?.Telegram.WebApp.BackButton.hide();
      window?.Telegram.WebApp.BackButton.offClick(handleBackButton);
    };

  }, []);


  const [formValue, setformValue] = useState(
    {
      "message": "Ссылка на запись",
      "text": "Записаться",
    }
  );
  const [submitEnabled, setSubmitEnabled] = useState(true);

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitEnabled(false);
    axios.post("/api/tenant_v1/post_bot_button", formValue).then((result) => {
      //setSubmitEnabled(true);
      GetTemplate((template) =>
        window?.Telegram?.WebApp.showAlert(template),
        "DialogSentBotButton"
      );
    });
  }

  return (
    <>

      <AdminPanel />

      <AdminMenu title="Запись клиентов из Telegram канала" />

      <Container>

        <Form onSubmit={handleSubmit} >

          <Form.Group className="mb-3">
            <Form.Label>Текст сообщения в закрепе</Form.Label>
            <Form.Control as="textarea" rows={2} name="message" value={formValue.message} onChange={handleChange} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Название кнопки в закрепе</Form.Label>
            <Form.Control as="textarea" rows={1} name="text" value={formValue.text} onChange={handleChange} />
          </Form.Group>

          <div className="text-center mb-5">
            <Button type="submit" variant="base" className="ps-4 pe-4" disabled={!submitEnabled} >Получить кнопку</Button>
          </div>
          <p><b>Как настроить запись из своего Telegram канала</b></p>

          <p>В вашем Telegram канале в закрепе можно разместить кнопку для записи.<br></br>
            Клиенты будут нажимать на эту кнопку и переходить к записи прямо из канала.
          </p>
          <p> Нажмите <b>Получить кнопку</b>, бот пришлет вам сообщение с кнопкий записи.<br></br>
            Опубликуйте это сообщение в своем канале и закрепите его.<br></br>
            Кнопка записи появится в закрепе.
          </p>
        </Form>

      </Container>
    </>
  );
};

export default AdminBotButton;
