import { Row, Col } from 'react-bootstrap';
import { DateTime } from 'luxon'
import { IconDelete, IconRestore, IconClock, IconCalendar, IconMoney } from "../../components/Icons.js";
import { useEffect, useState, useContext } from "react";
import { StatusName, AppContext } from "../../App.js";
import "./Style.css";

const ClientRecordItem = (props) => {
    const { item, variant, cancelRecord, restoreRecord, handleCancelRecord, handleRestoreRecord } = props;

    const [appData, setAppContext] = useContext(AppContext);

    return (
        <div className={"item " + variant}>
            <Row className={"p-2"}>

                <Col className='col-10 '>
                    <IconCalendar className="me-2" />
                    {DateTime.fromISO(item.start).setLocale("ru").toFormat("d MMMM, cccc")} {" "}
                    <br />
                    <IconClock className="me-2" />
                    {DateTime.fromISO(item.start).toFormat("H:mm")}
                    {" "}-{" "}
                    {DateTime.fromISO(item.end).toFormat("H:mm")}
                </Col>

                <Col className='col-2'>
                    {cancelRecord
                        && ["created", "not_confirmed", "confirmed"].includes(item.status)
                        && (
                            <Col className="col-1 ps-0">
                                <span variant={"outline"} className="w-2" onClick={() => handleCancelRecord(item)}><IconDelete /></span>
                            </Col >
                        )
                    }

                    {restoreRecord
                        && ["canceled", "skipped"].includes(item.status)
                        //  && (DateTime.fromISO(item.start) > DateTime.now().plus({ milliseconds: appData.time_zone_delta }))
                        && (
                            <Col className="col-1 ps-0">
                                <span variant={"outline"} className="w-2" onClick={() => handleRestoreRecord(item)}><IconRestore /></span>
                            </Col >
                        )
                    }
                </Col>

                <Col className='col-12 pt-2 pb-2'>
                    {item?.basket.map((i, index) => (
                        < Row key={index} >
                            <Col className='col-8 '>{i.name}</Col>
                            <Col className='col-4 text-end'>{i.price.toLocaleString('ru-RU')}{" ₽"} </Col>
                        </Row>
                    ))}
                </Col>

                <Col className='col-1 pe-0'>
                    <IconMoney />
                </Col>

                <Col className='col-11 '>

                    <Row >
                        <Col className='col-8 '>Итого услуги</Col>
                        <Col className='col-4  text-end'>{item.price.toLocaleString('ru-RU')}{" ₽"} </Col>
                    </Row>

                    {item.pay_sum !== 0 && (
                        <Row >
                            <Col className='col-8 '>Оплачено</Col>
                            <Col className='col-4  text-end'>{item.pay_sum.toLocaleString('ru-RU')}{" ₽"} </Col>
                        </Row>
                    )}

                    {(item.result_sum) !== 0 && (
                        <Row >
                            <Col className='col-8 '>{item.result_sum > 0 ? "Переплата" : "Долг"}</Col>
                            <Col className='col-4  text-end'>{Math.abs(item.result_sum).toLocaleString('ru-RU')}{" ₽"} </Col>
                        </Row>
                    )}

                </Col>

                <Col className='col-12 pt-2'>
                    {StatusName[item.status]}
                </Col>
            </Row >
        </div >
    );
};

export default ClientRecordItem;
